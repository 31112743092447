import { getClassProperties } from '../../util';
import BackendError from '../Error/BackendError';

const statusTypes = {
  SUCCESS: 'success',
  ERROR: 'error',
};

class ResponseBody {
  constructor(status, data) {
    this.status = status;
    this.data = data;
    this.statusTypes = statusTypes;
  }

  generateJson() {
    return getClassProperties(this);
  }

  buildFromJson(fields) {
    const { status, data } = fields;
    this.status = status;
    this.data = data;
    return this;
  }

  extractDataOrError() {
    if (this.status === this.statusTypes.ERROR) {
      return new BackendError().buildFromJson(this.data);
    }
    // could check for success
    return this.data;
  }
}

ResponseBody.statusTypes = statusTypes;

export default ResponseBody;
