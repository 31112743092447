import React from 'react';
import RBAccordion from 'react-bootstrap/Accordion';

export default function Accordian({ title, children }) {
  return (
    <RBAccordion>
      <RBAccordion.Item eventKey={0}>
        <RBAccordion.Header>{title}</RBAccordion.Header>
        <RBAccordion.Body>{children}</RBAccordion.Body>
      </RBAccordion.Item>
    </RBAccordion>
  );
}
