import {
  React, useContext, useEffect, useReducer,
} from 'react';
import PropTypes from 'prop-types';
import OrderModifyForm from './OrderModifyForm';

import { OrderDataService } from '../../Config/Services';
import Order from '../../Shared/Domain/Order/Order.entity';
import orderStateReducer, { buildInitialState, ibsOrderDataActions } from './orderState.reducer';
import CurrentActionDisplay from './CurrentActionDisplay';
import OrderIdForm from '../OrderIdForm/OrderIdForm';
import { NotificationContainer } from '../FormElements/FormElements';
import OrderEnvironmentSelector from '../OrderEnvironmentSelector/OrderEnvironmentSelector';
import { InitialOrderContext } from '../../Context/InitialOrderContext';
import { AuthenticationContext } from '../../Context/AuthenticationContext';
import { EnvironmentContext, EnvironmentValue } from '../../Context/EnvironmentContext';
import { fetchAvailability, fetchOrderData, submitModification } from './OrderActions.service';
import AuthenticationValue from '../../Domain/AuthenticationValue';

export default function OrderDisplay() {
  const initialOrderContext = useContext(InitialOrderContext);
  /** @type {AuthenticationValue} */ const authenticationContext = useContext(AuthenticationContext);
  /** @type {EnvironmentValue} */ const appEnvironment = useContext(EnvironmentContext);

  const [state, dispatch] = useReducer(
    orderStateReducer,
    buildInitialState(initialOrderContext, appEnvironment),
  );

  const orderService = new OrderDataService(state.orderEnvironmentId, authenticationContext, appEnvironment);

  const availability = state.availability;
  /** @type {Order} */
  const order = state.order;
  const { orderId } = state.orderDetails;

  const orderActionDependencies = {
    state,
    dispatch,
    orderService,
  };

  useEffect(() => {
    switch (state.action.type) {
      case ibsOrderDataActions.SEND_ORDER:
        fetchOrderData(orderActionDependencies);
        break;
      case ibsOrderDataActions.SEND_AVAILABILITY:
        fetchAvailability(orderActionDependencies);
        break;
      case ibsOrderDataActions.SEND_MODIFICATION:
        submitModification(orderActionDependencies);
        break;
      default:
        break;
    }
  }, [state.action.type]);

  const display = [];

  display.push(
    <div>
      <OrderEnvironmentSelector dispatch={dispatch} orderEnvironmentId={state.orderEnvironmentId} />
      <OrderIdForm dispatch={dispatch} orderDetails={state.orderDetails} />
      <NotificationContainer>
        { state.notifications }
      </NotificationContainer>

    </div>,
  );

  if (orderId == null) {
    // not entered order id yet
  } else if (order === null) {
    // loading data
  } else {
    display.push(
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <OrderModifyForm
          dispatch={dispatch}
          availability={availability}
          order={order}
        />
      </div>,
    );
  }

  if (state.action.type !== null) {
    display.push(<CurrentActionDisplay action={state.action} />);
  }

  return display;
}

OrderDisplay.defaultProps = {
  orderId: null,
  orderData: null,
};
