export default class Guest {
  constructor() {
    this.fields = {};
    this.fields.id = undefined;
    this.fields.supplierId = undefined;
    this.fields.givenName = undefined;
    this.fields.surname = undefined;
    this.fields.age = undefined;
    // setters for each field
    Object.keys(this.fields).forEach((field) => {
      this[`set${field.charAt(0).toUpperCase() + field.slice(1)}`] = (value) => {
        this.fields[field] = value;
      };
    });
    this.delete = false;
  }

  buildFromExternalJson(id, resGuest) {
    const names = resGuest.profiles.profileInfo.profile.customer.personName;
    this.fields.id = id;
    this.fields.supplierId = resGuest.resGuestRph;
    this.fields.givenName = names.givenName;
    this.fields.surname = names.surname;
    this.fields.age = resGuest.age;
    return this;
  }

  buildFromInternalJson(json) {
    this.fields = { ...this.fields, ...json };
    return this;
  }

  /**
     * @returns {string} identifier of the guest
     */
  getInternalId() {
    return this.fields.id;
  }

  setInternalId(value) {
    this.fields.id = value;
  }

  getSupplierId() {
    return this.fields.supplierId;
  }

  setSupplierId(value) {
    this.fields.supplierId = value;
  }

  getGivenName() {
    return this.fields.givenName;
  }

  getSurname() {
    return this.fields.surname;
  }

  /**
   *
   * @returns {string}
   */
  getAge() {
    return String(this.fields.age);
  }

  generateInternalJson() {
    return this.fields;
  }
}
