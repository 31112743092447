import { bindFunction } from '../../util';
import Repo from '../Repo';
import Room from './Room.entity';

/**
 * @param {object} orderData
 */
function extractDataFromInternalJson(orderData) {
  if (orderData === null) { return []; }
  return orderData.rooms;
}

function extractDataFromExternalJson(orderData) {
  return orderData.roomStays.roomStays;
}

export default class RoomRepo {
  constructor() {
    // composition - from generic repo class
    const repo = new Repo(Room);
    this.setElement = bindFunction(repo.setElement, repo);
    this.getElement = bindFunction(repo.getElement, repo);
    this.removeElementsByIds = bindFunction(repo.removeElementsByIds, repo);
    this.getElementsByIds = bindFunction(repo.getElementsByIds, repo);
    this.removeElement = bindFunction(repo.removeElement, repo);
    this.getAllElements = bindFunction(repo.getAllElements, repo);
    this.getAllIds = bindFunction(repo.getAllIds, repo);
    this.generateNextNumericId = bindFunction(repo.generateNextNumericId, repo);
    this.clearElements = bindFunction(repo.clearElements, repo);
    this.renumberElements = bindFunction(repo.renumberElements, repo);
  }

  /**
   *
   */
  buildFromInternalJson(orderData, allGuests, allServices) {
    const releventJsonArray = extractDataFromInternalJson(orderData);
    Object.values(releventJsonArray).forEach((elementJson) => {
      const room = new Room().buildFromInternalJson(elementJson, allGuests, allServices);
      // room.saveStateInFieldHistory();
      this.setElement(room.getInternalId(), room);
    });
    return this;
  }

  /**
   *
   * @param {RoomRepo} roomRepo
   */
  buildFromObject(roomRepo, allGuests, allServices) {
    roomRepo.getAllElements().forEach((room) => {
      const newRoom = new Room().buildFromObject(room, allGuests, allServices);
      this.setElement(newRoom.getInternalId(), newRoom);
    });
    return this;
  }

  buildFromExternalJson(orderData, allGuests, allServices) {
    const releventJsonArray = extractDataFromExternalJson(orderData);
    Object.values(releventJsonArray).forEach((elementJson) => {
      const room = new Room();
      const id = this.generateNextNumericId();
      room.buildFromExternalJson(id, elementJson, allGuests, allServices);
      this.setElement(room.getInternalId(), room);
    });
    return this;
  }

  buildFromAvailabilityJson(availabilityRooms, searchInfo) {
    availabilityRooms.rooms.forEach((roomJson) => {
      const roomInfo = {
        roomType: roomJson.room_type,
        roomDescription: roomJson.room_description,
      };
      roomJson.packages.forEach((packageJson) => {
        const id = this.generateNextNumericId();
        const room = new Room().buildFromAvailabilityJson(
          String(id),
          packageJson,
          searchInfo,
          roomInfo,
        );
        this.setElement(id, room);
      });
    });
    return this;
  }

  /**
   *
   */
  generateInternalJson() {
    const jsonOutput = {};
    this.getAllElements().forEach((room) => {
      jsonOutput[room.getInternalId()] = room.generateInternalJson();
    });
    return jsonOutput;
  }

  createNewElement() {
    const newId = String(this.generateNextNumericId());
    const room = new Room();
    room.setInternalId(newId);
    return room;
  }
}
