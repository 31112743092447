import usePermissionsCheck from '../../Hooks/usePermissionsCheck';

export default function DisplayIfUserHasPermissions({ permissionNames, children }) {
  const hasPermissions = usePermissionsCheck(...permissionNames);

  if (hasPermissions) {
    return (children);
  }

  return [];
}
