const environmentSettings = {
  STANDALONE: {
    id: 'standalone',
    // on dev will use proxy "localhost:5000" else will use current domain
    backendDomain: '',
    fixedOrderEnv: null,
    atdDomain: null,
  },
  STAGE3: {
    id: 'stage3',
    backendDomain: 'https://services3.atdtravel.com',
    fixedOrderEnv: 'stage3',
    atdDomain: 'https://atd3.atdtravel.com',
  },
  STAGE4: {
    id: 'stage4',
    backendDomain: 'https://services3.atdtravel.com',
    fixedOrderEnv: 'stage4',
    atdDomain: 'https://atd4.atdtravel.com',
  },
  PROD: {
    id: 'prod',
    backendDomain: 'https://services3.atdtravel.com',
    fixedOrderEnv: 'prod',
    atdDomain: 'https://www.attraction-tickets-direct.co.uk',
  },
  DEV: {
    id: 'dev',
    backendDomain: 'http://localhost:5000',
    fixedOrderEnv: 'dev',
    atdDomain: 'https://atd.test',
  },
};

export default environmentSettings;
