import React, {
  useEffect, useMemo, useState, useContext,
} from 'react';
import environmentSettings from '../Config/EnvironmentSettings';
import { standalonePermissions } from '../Config/Permissions';
import AuthenticationValue from '../Domain/AuthenticationValue';
import AtdRolesService from '../Services/AtdRoles.service';
import { EnvironmentContext, EnvironmentValue } from './EnvironmentContext';

/**
 *
 * @param {AuthenticationValue} newContextValue
 * @param {*} setContextValue
 */
function authenticateAsStandalone(newContextValue) {
  newContextValue.setPermissions(...standalonePermissions);
  return newContextValue;
}

/**
 *
 * @param {*} apiSid
 * @param {EnvironmentValue} appEnvironment
 * @returns
 */
async function getAtdRoles(apiSid, appEnvironment) {
  const rolesService = new AtdRolesService();

  let roles = [];
  const response = await rolesService.requestAtdRoles(
    apiSid,
    appEnvironment.getAtdDomain(),
  );
  if (response.status === response.statusTypes.SUCCESS) {
    const data = response.extractDataOrError();
    if (data.roles) {
      roles = [...data.roles];
    }
  }
  return roles;
}

/**
 *
 * @param {AuthenticationValue} newContextValue
 * @param {EnvironmentValue} appEnvironment
 */
async function authenticateUsingApiSid(newContextValue, appEnvironment) {
  try {
    const apiSid = localStorage.getItem('apiSid');
    if (apiSid != null) {
      newContextValue.setApiSid(apiSid);
      const roles = await getAtdRoles(apiSid, appEnvironment);
      newContextValue.setAtdRoles(roles);
    } else {
      throw new Error('No apiSid');
    }
  } catch (e) {
    newContextValue.setUserAsUnauthenticated();
  }
  return newContextValue;
}

export const AuthenticationContext = React.createContext();

export const AuthenticationContextProvider = ({ children }) => {
  /** @type {EnvironmentValue} */
  const appEnvironment = useContext(EnvironmentContext);
  const [contextValue, setContextValue] = useState(new AuthenticationValue());

  const isStandalone = useMemo(() => {
    if (appEnvironment.envId === environmentSettings.STANDALONE.id) {
      return true;
    }
    return false;
  }, [appEnvironment.envId]);

  async function updateAuthenticationValue() {
    let newContextValue = new AuthenticationValue();
    if (isStandalone) {
      newContextValue = authenticateAsStandalone(newContextValue);
    } else {
      newContextValue = authenticateUsingApiSid(newContextValue, appEnvironment);
    }
    newContextValue.setPendingStatus(false);
    setContextValue(newContextValue);
  }

  useEffect(updateAuthenticationValue, []);

  return (
    <AuthenticationContext.Provider value={contextValue}>
      { children }
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationContext;
