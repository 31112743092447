import { bindFunction } from '../../util';
import Repo from '../Repo';
import Service from './Service.entity';

function extractDataFromInternalJson(orderData) {
  // TODO error handle
  if (orderData === null) {
    return [];
  }
  return orderData.services;
}

function extractDataFromExternalJson(orderData) {
  return orderData.services;
}

export default class ServiceRepo {
  constructor() {
    // composition - from generic repo class
    const repo = new Repo(Service);
    this.setElement = bindFunction(repo.setElement, repo);
    this.getElement = bindFunction(repo.getElement, repo);
    this.getElementsByIds = bindFunction(repo.getElementsByIds, repo);
    this.removeElement = bindFunction(repo.removeElement, repo);
    this.removeElementsByIds = bindFunction(repo.removeElementsByIds, repo);
    this.getAllElements = bindFunction(repo.getAllElements, repo);
    this.getAllIds = bindFunction(repo.getAllIds, repo);
    this.generateNextNumericId = bindFunction(repo.generateNextNumericId, repo);
    this.clearElements = bindFunction(repo.clearElements, repo);
    this.renumberElements = bindFunction(repo.renumberElements, repo);
  }

  buildFromInternalJson(orderData, allGuests) {
    const releventJsonArray = extractDataFromInternalJson(orderData);
    Object.values(releventJsonArray).forEach((elementJson) => {
      const service = new Service().buildFromInternalJson(elementJson, allGuests);
      this.setElement(service.getInternalId(), service);
    });
    return this;
  }

  buildFromExternalJson(orderData, allGuests) {
    const releventJsonArray = extractDataFromExternalJson(orderData);
    Object.values(releventJsonArray).forEach((elementJson) => {
      const id = this.generateNextNumericId();
      const service = new Service().buildFromExternalJson(id, elementJson, allGuests);
      this.setElement(service.getInternalId(), service);
    });
    return this;
  }

  buildFromAvailabilityJson(availabilityAddons) {
    // TODO split prices into separate services
    availabilityAddons.forEach((addOn) => {
      const prices = addOn.price;
      prices.forEach((priceElement) => {
        const id = this.generateNextNumericId();
        const service = new Service().buildFromAvailabilityJson(String(id), addOn, priceElement);
        this.setElement(id, service);
      });
    });
    return this;
  }

  /**
   *
   */
  generateInternalJson() {
    const jsonOutput = {};
    this.getAllElements().forEach((service) => {
      jsonOutput[service.getInternalId()] = service.generateInternalJson();
    });
    return jsonOutput;
  }

  createNewElement() {
    const newId = String(this.generateNextNumericId());
    const service = new Service();
    service.setInternalId(newId);
    return service;
  }
}
