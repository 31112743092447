// can replace this file to replace all form elements in the app
import { React, JSX } from 'react';
import PropTypes from 'prop-types';
import {
  MDBInput, MDBBtn, MDBNotification, MDBContainer,
} from 'mdbreact';
import MultiSelect from 'react-multi-select-component';
import styles from './FormElements.module.css';

/**
 * @param {{value: string, onChange: Function, label: string, disabled: boolean}} props
 */
export function TextInput({
  value, onChange, label, disabled,
}) {
  return <MDBInput disabled={disabled} value={value} onChange={onChange} label={label} />;
}
TextInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string.isRequired,
};

TextInput.defaultProps = {
  onChange: () => {},
};

/**
 * @param {object} root0
 * @param {Function} root0.onClick
 * @param {string} root0.type
 * @param {React.children} root0.children
 * @param {boolean} root0.disabled
 */
export function Button({
  onClick, type, children, disabled,
}) {
  return <MDBBtn disabled={disabled} onClick={onClick} type={type}>{children}</MDBBtn>;
}
Button.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  type: '',
  children: '',
  onClick: () => {},
  disabled: false,
};

/**
 * @param {{value:string, onChange: Function}} param
 */
export function SelectInput({ value, options, onChange }) {
  const optionsDisplay = Object.entries(options).map(([key, val]) => <option value={val}>{key}</option>);
  return (
    <select className={styles.selectInput} onChange={onChange} value={value}>
      {optionsDisplay}
    </select>
  );
}

SelectInput.propTypes = {
  value: PropTypes.string.isRequired,
  options: PropTypes.objectOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};

/**
 *
 * @param {{value: string[], options: Object<string,string>}} props
 * @returns
 */
export function MultiSelectInput({ value, options, onChange }) {
  function handleChange(inputValue) {
    const returnValue = [];
    inputValue.forEach(({ label, value: val }) => returnValue.push(val));
    onChange({ target: { value: returnValue } });
  }

  const formattedOptions = Object.entries(options).map(([label, val]) => ({ label, value: val }));
  const formattedValue = [];
  formattedOptions.forEach(({ value: val }, i) => {
    if (value.includes(val)) {
      formattedValue.push(formattedOptions[i]);
    }
  });

  return <MultiSelect className={styles.rmsc} hasSelectAll={false} options={formattedOptions} value={formattedValue} onChange={handleChange} />;
}

export function DatePicker({ value, onChange, disabled }) {
  return <input style={{ height: 'fit-content' }} disabled={disabled} type="date" value={value} onChange={onChange} />;
}

export function NotificationContainer({ children }) {
  return (
    <MDBContainer
      style={{
        position: 'fixed',
        bottom: '10px',
        right: '10px',
        zIndex: 9999,
      }}
    >
      {children}
    </MDBContainer>
  );
}

export const notificationTypes = {
  ERROR: 'error',
  INFO: 'info',
};

export function Notification({ title, message, type }) {
  let icon;
  let className;
  let iconClassName;
  let autohide;
  let titleClassName;
  if (type === notificationTypes.ERROR) {
    icon = 'exclamation';
    iconClassName = 'white-text';
    className = '';
    titleClassName = styles.redNotification;
    autohide = 0;
  } else {
    icon = 'info-circle';
    iconClassName = 'text-primary';
    className = '';
    titleClassName = '';
    autohide = 10000;
  }
  const bodyClassName = styles.lightGreyNotificationBody;
  return (
    <MDBNotification
      show
      fade
      autohide={autohide}
      className={className}
      iconClassName={iconClassName}
      bodyClassName={bodyClassName}
      titleClassName={titleClassName}
      icon={icon}
      title={title}
      message={message}
    />
  );
}
