import { bindFunction } from '../../util';
import Repo from '../Repo';
import Guest from './Guest.entity';

/**
 * @param {object} orderData
 */
function extractDataFromInternalJson(orderData) {
  return orderData.guests;
}

function extractDataFromExternalJson(orderData) {
  return orderData.resGuests;
}

export default class GuestRepo {
  constructor() {
    // composition - from generic repo class
    const repo = new Repo(Guest);
    this.setElement = bindFunction(repo.setElement, repo);
    this.getElement = bindFunction(repo.getElement, repo);
    this.getElementsByIds = bindFunction(repo.getElementsByIds, repo);
    this.removeElement = bindFunction(repo.removeElement, repo);
    this.removeElementsByIds = bindFunction(repo.removeElementsByIds, repo);
    this.getAllElements = bindFunction(repo.getAllElements, repo);
    this.getAllIds = bindFunction(repo.getAllIds, repo);
    this.generateNextNumericId = bindFunction(repo.generateNextNumericId, repo);
    this.clearElements = bindFunction(repo.clearElements, repo);
    this.renumberElements = bindFunction(repo.renumberElements, repo);
  }

  /**
   *
   */
  buildFromInternalJson(orderData) {
    const releventJsonArray = extractDataFromInternalJson(orderData);
    Object.values(releventJsonArray).forEach((elementJson) => {
      const guest = new Guest().buildFromInternalJson(elementJson);
      this.setElement(guest.getInternalId(), guest);
    });
    return this;
  }

  buildFromExternalJson(orderData) {
    const releventJsonArray = extractDataFromExternalJson(orderData);
    Object.values(releventJsonArray).forEach((elementJson) => {
      const id = this.generateNextNumericId();
      const guest = new Guest().buildFromExternalJson(id, elementJson);
      this.setElement(guest.getInternalId(), guest);
    });
    return this;
  }

  /**
   *
   */
  generateInternalJson() {
    const jsonOutput = {};
    this.getAllElements().forEach((guest) => { jsonOutput[guest.getInternalId()] = guest.generateInternalJson(); });
    return jsonOutput;
  }

  createNewElement() {
    const newId = String(this.generateNextNumericId());
    const guest = new Guest();
    guest.setInternalId(newId);
    return guest;
  }
}
