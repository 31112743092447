/**
 * @template T
 */
export default class Repo {
  /**
   * @param {new() => T} type
   */
  constructor(type) {
    /**
     * @type {Object<string, T>}
     */
    this.elements = {};
  }

  /**
   * @param {string} id
   * @param {T} element
   */
  setElement(id, element) {
    this.elements[id] = element;
  }

  /**
   * @param {string} id
   */
  removeElement(id) {
    delete this.elements[id];
  }

  clearElements() {
    this.elements = {};
  }

  /**
   * @param {string} id
   * @returns {T}
   */
  getElement(id) {
    return this.elements[id];
  }

  /**
   * @param {string[]} ids
   * @returns {T[]}
   */
  getElementsByIds(ids) {
    const returnElements = [];
    ids.forEach((id) => {
      const element = this.getElement(id);
      if (!element) {
        console.log(`attempting to retrieve a non-existing element from a repo using id: ${id}`);
      }
      returnElements.push(element);
    });
    return returnElements;
  }

  removeElementsByIds(ids) {
    ids.forEach((id) => {
      this.removeElement(id);
    });
  }

  /**
   *
   * @returns {T[]}
   */
  getAllElements() {
    return Object.values(this.elements);
  }

  /**
   *
   * @returns {String[]}
   */
  getAllIds() {
    return Object.keys(this.elements);
  }

  generateNextNumericId() {
    let id = 1;
    Object.values(this.elements).forEach((element) => {
      if (element.getInternalId() >= id) {
        id = Number(element.getInternalId()) + 1;
      }
    });
    return String(id);
  }

  renumberElements() {
    const currentElements = this.getAllElements();
    currentElements.forEach((element, i) => {
      const newSupplierId = String((i + 1));
      element.setSupplierId(newSupplierId);
      this.setElement(element.getInternalId(), element);
    });
  }
}
