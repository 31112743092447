export default class SupplierCode {
  constructor(name, value) {
    this.name = name;
    this.value = value;
  }

  generateJson() {
    return {
      name: this.name,
      value: this.value,
    };
  }

  static buildFromJson(json) {
    return new SupplierCode(json.name, json.value);
  }

  getName() {
    return this.name;
  }

  getValue() {
    return this.value;
  }
}
