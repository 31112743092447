import { React } from 'react';
import PropTypes from 'prop-types';

/**
 *
 * @param {{children: object}} param0
 */
export default function JSONDisplay({ children }) {
  return (
    <div style={{ wordBreak: 'break-word' }}>
      <div>
        <p>{JSON.stringify(children)}</p>
      </div>
    </div>
  );
}

JSONDisplay.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};
