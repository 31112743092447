/* eslint-disable no-unused-vars */
import { getSafely, removeValuesFromArray } from '../../util';
import Guest from '../Guests/Guest.entity';
import GuestRepo from '../Guests/Guests.repo';
import Service from '../Services/Service.entity';
import ServiceRepo from '../Services/Services.repo';

function buildHistoryStateInFriendlyString(historyState) {
  if (historyState == null) {
    return '';
  }
  return new Room()
    .buildUsingOnlyFields(historyState)
    .buildOptionKey();
}

/**
 *
 * @param {string} value
 * @param {string[]} array
 */
function addStringToArrayIfNotIncluded(value, array) {
  let returnArray = array;
  if (value) {
    const strValue = String(value);
    if (!(array.includes(strValue))) {
      returnArray = [...array, strValue];
    }
  }
  return returnArray;
}

/**
   * @param {Object[]} packageAvailability
   * @param {string} currency
   */
function buildTotalCostFromAvailability(packageAvailability, currency) {
  const startVal = {
    beforeTax: 0,
    afterTax: 0,
  };
  const totalCost = packageAvailability.reduce((prev, curr) => (
    {
      beforeTax: Number(prev.beforeTax) + Number(curr.cost_before_tax),
      afterTax: Number(prev.afterTax) + Number(curr.cost_after_tax),
    }
  ), startVal);

  return {
    beforeTax: String(totalCost.beforeTax.toFixed(2)),
    afterTax: String(totalCost.afterTax.toFixed(2)),
    currency,
  };
}

/**
 * @param {Object[]} packageAvailability
 * @returns
 */
function buildLengthOfStayFromAvailability(packageAvailability) {
  return `P${packageAvailability.length}N`;
}

function buildRoomRate(effectiveDate, expireDate, unitMultiplier, amountBeforeTax, amountAfterTax, currency) {
  return {
    startDate: effectiveDate,
    endDate: expireDate,
    unitMultiplier,
    unitCost: {
      beforeTax: amountBeforeTax,
      afterTax: amountAfterTax,
      currency,
    },
  };
}

/**
 * @param {Object[]} packageAvailability
 */
function buildRoomRatesFromAvailability(packageAvailability, currency) {
  const priceGroups = [];
  let beforeTax = null;
  let afterTax = null;
  packageAvailability.forEach((day) => {
    let prevPriceGroup = null;
    if (priceGroups.length > 0) {
      prevPriceGroup = priceGroups[priceGroups.length - 1];
    }
    if (priceGroups.length < 1
      || (day.cost_before_tax !== beforeTax && day.cost_after_tax !== afterTax)) {
      if (prevPriceGroup) {
        prevPriceGroup.nextDate = day.date;
      }
      priceGroups.push({
        startDate: day.date, nextDate: null, headerElement: day, dates: [day.date],
      });
      beforeTax = day.cost_before_tax;
      afterTax = day.cost_after_tax;
    } else {
      prevPriceGroup.dates.push(day.date);
    }
  });

  if (packageAvailability.length > 0) {
    const dateString = packageAvailability[packageAvailability.length - 1].date;
    const date = new Date(dateString);
    date.setDate(date.getDate() + 1);
    const dateYmd = date.toISOString().split('T')[0];
    priceGroups[priceGroups.length - 1].nextDate = dateYmd;
  }
  return priceGroups.map(({
    startDate, nextDate, dates, headerElement,
  }) => buildRoomRate(
    startDate, nextDate, dates.length,
    headerElement.cost_before_tax, headerElement.cost_after_tax, currency,
  ));
}

function getBlankFields() {
  return {
    id: null,
    supplierId: null,
    serviceIds: [],
    guestIds: [],
    startDate: null,
    endDate: null,
    roomType: null,
    ratePlan: null,
    totalCost: null,
    rates: null,
    hotelCode: null,
    hotelCityCode: null,
    lengthOfStay: null,
    packageType: null,
    friendlyName: null,
    atdProductId: null,
  };
}

export default class Room {
  constructor() {
    this.fields = getBlankFields();
    this.fieldsHistory = [];
    this.delete = false;
  }

  // *****************
  // IMPORT
  // *****************

  buildFromAvailabilityJson(id, packageJson, searchInfo, roomInfo) {
    // TODO consider meal plan, package type, limiting selectable add ons
    this.fields.id = id;
    this.fields.startDate = searchInfo.startDate;
    this.fields.endDate = searchInfo.endDate;
    this.fields.roomType = roomInfo.roomType;
    this.fields.ratePlan = packageJson.rate_plan;
    this.fields.packageType = packageJson.package_type;
    this.fields.totalCost = buildTotalCostFromAvailability(
      packageJson.availability, searchInfo.currency,
    );
    this.fields.rates = buildRoomRatesFromAvailability(
      packageJson.availability,
      searchInfo.currency,
    );
    this.fields.hotelCode = searchInfo.hotelId;
    this.fields.hotelCityCode = '  ';
    this.fields.lengthOfStay = buildLengthOfStayFromAvailability(packageJson.availability);
    this.availabilityAddonNumbers = packageJson.addons;
    return this;
  }

  buildFromExternalJson(id, roomStayElement) {
    const { roomStay } = roomStayElement;
    this.setInternalId(id);
    this.setSupplierId(id);
    this.fields.startDate = roomStay.timeSpan.start;
    this.fields.endDate = roomStay.timeSpan.end;
    this.fields.totalCost = {
      beforeTax: roomStay.total.amountBeforeTax,
      afterTax: roomStay.total.amountAfterTax,
      currency: roomStay.total.currencyCode,
    };
    this.fields.serviceIds = roomStay.serviceRphs.map(
      (serviceRphsElement) => (serviceRphsElement.serviceRph.rph),
    );
    this.fields.guestIds = roomStay.resGuestRphs.map(
      (resGuestRphsElement) => (resGuestRphsElement.resGuestRph.rph),
    );
    this.fields.roomType = roomStay.roomTypes[0].roomType.roomTypeCode;
    this.fields.ratePlan = roomStay.ratePlans[0].ratePlan.ratePlanCode;
    this.fields.rates = roomStay.roomRates[0].roomRate.rates.map((ratesElement) => {
      const { rate } = ratesElement;
      return buildRoomRate(
        rate.effectiveDate, rate.expireDate, rate.unitMultiplier,
        rate.base.amountBeforeTax, rate.base.amountAfterTax, rate.base.currencyCode,
      );
    });
    this.fields.hotelCode = roomStay.basicPropertyInfo.hotelCode;
    this.fields.hotelCityCode = roomStay.basicPropertyInfo.hotelCityCode;
    this.fields.lengthOfStay = roomStay.timeSpan.duration;

    return this;
  }

  /**
   *
   * @param {Room} room
   * @param {*} allGuests
   * @param {*} allServices
   */
  buildFromObject(room) {
    this.fields = { ...this.fields, ...room.generateInternalJson() };
    this.fieldsHistory = [...room.fieldsHistory];
    return this;
  }

  buildUsingOnlyFields(json) {
    this.fields = { ...this.fields, ...json };
    return this;
  }

  buildFromSelectedAvailability(avRoom, allGuests, allServices) {
    const newRoom = new Room().buildFromObject(avRoom, allGuests, allServices);
    newRoom.setGuestIds(this.getGuestIds());
    newRoom.setServiceIds(this.getServiceIds());
    newRoom.setInternalId(this.getInternalId());
    newRoom.setSupplierId(this.getSupplierId());
    return newRoom;
  }

  /**
   *
   * @param {*} json
   * @returns
   */
  buildFromInternalJson(json) {
    this.fields = { ...this.fields, ...json };

    return this;
  }

  // ----------------------
  /**
   *
   * @param {Room} room
   * @returns
   */
  isMatchingRoom(room) {
    if (JSON.stringify(this.generateIdentifierInformation())
    === JSON.stringify(room.generateIdentifierInformation())) {
      return true;
    }
    return false;
  }

  resetForAvailability() {
    const newFields = getBlankFields();
    const id = this.getInternalId();
    const guestIds = this.getGuestIds();
    const serviceIds = this.getServiceIds();
    const hotelCode = this.getHotelCode();

    this.fields = newFields;

    this.setInternalId(id);
    this.setGuestIds(guestIds);
    this.setServiceIds(serviceIds);
    this.setHotelCode(hotelCode);
  }

  getHistoryStateByIndex(index) {
    // stack: 0 is current, 1 is previous etc.
    return getSafely(() => this.fieldsHistory[index]);
  }

  getPreviousStateAsFriendlyString() {
    return buildHistoryStateInFriendlyString(this.getHistoryStateByIndex(1));
  }

  getOriginalStateAsFriendlyString() {
    const index = this.fieldsHistory.length - 1;
    if (index <= 1) {
      return '';
    }
    return buildHistoryStateInFriendlyString(this.getHistoryStateByIndex(index));
  }

  buildOptionKey() {
    let packageType = this.getPackageType();
    if (packageType) {
      packageType = `: ${packageType}`;
    } else {
      packageType = '';
    }

    const friendlyName = `${this.getFriendlyName() || '*'}`;
    return `${friendlyName} (${this.getRoomType()} : ${this.getRatePlan()}${packageType})`;
  }

  setInternalId(id) {
    this.fields.id = id;
  }

  getInternalId() {
    return this.fields.id;
  }

  getSupplierId() {
    return this.fields.supplierId;
  }

  setSupplierId(value) {
    this.fields.supplierId = value;
  }

  getServiceIds() {
    return this.fields.serviceIds;
  }

  /**
   *
   * @param {string[]} value
   */
  setServiceIds(value) {
    this.fields.serviceIds = value;
  }

  removeServiceId(value) {
    const ids = removeValuesFromArray(this.getServiceIds(), value);
    this.setServiceIds(ids);
  }

  /**
   *
   * @returns {string[]}
   */
  getGuestIds() {
    return this.fields.guestIds;
  }

  /**
   * @param {String[]} value
   */
  setGuestIds(value) {
    this.fields.guestIds = value;
  }

  removeGuestId(value) {
    const ids = removeValuesFromArray(this.getGuestIds(), value);
    this.setGuestIds(ids);
  }

  /**
   * idempotent
   */
  addGuestId(value) {
    this.setGuestIds(
      addStringToArrayIfNotIncluded(value, this.getGuestIds()),
    );
  }

  /**
   * idempotent
   */
  addServiceId(value) {
    this.setServiceIds(
      addStringToArrayIfNotIncluded(value, this.getServiceIds()),
    );
  }

  getRoomType() {
    return this.fields.roomType;
  }

  getPackageType() {
    return this.fields.packageType;
  }

  getRatePlan() {
    return this.fields.ratePlan;
  }

  getHotelCode() {
    return this.fields.hotelCode;
  }

  getHotelCityCode() {
    return this.fields.hotelCityCode;
  }

  setHotelCode(value) {
    this.fields.hotelCode = value;
  }

  getStartDate() {
    return this.fields.startDate;
  }

  setStartDate(value) {
    this.fields.startDate = value;
  }

  getEndDate() {
    return this.fields.endDate;
  }

  setEndDate(value) {
    this.fields.endDate = value;
  }

  getTotalBeforeTax() {
    if (this.fields.totalCost == null) {
      return undefined;
    }
    return this.fields.totalCost.beforeTax;
  }

  getTotalAfterTax() {
    if (this.fields.totalCost == null) {
      return undefined;
    }
    return this.fields.totalCost.afterTax;
  }

  getTotalCurrency() {
    return this.fields.totalCost.currency;
  }

  getFriendlyName() {
    return this.fields.friendlyName;
  }

  setFriendlyName(value) {
    this.fields.friendlyName = value;
  }

  getAtdProductId() {
    return this.fields.atdProductId;
  }

  setAtdProductId(value) {
    this.fields.atdProductId = value;
  }

  getLengthOfStay() {
    return this.fields.lengthOfStay;
  }

  // *****************
  // EXPORT
  // *****************

  generateIdentifierInformation() {
    return {
      roomType: this.getRoomType(),
      ratePlan: this.getRatePlan(),
    };
  }

  generateInternalJson() {
    // could check if fields are all set
    return this.fields;
  }
}
