export default class Search {
  constructor() {
    this.fields = {
      hotelId: null,
      startDate: null,
      endDate: null,
    };
  }

  getHotelId() {
    return this.fields.hotelId;
  }

  setHotelId(value) {
    this.fields.hotelId = value;
  }

  getStartDate() {
    return this.fields.startDate;
  }

  setStartDate(value) {
    this.fields.startDate = value;
  }

  getEndDate() {
    return this.fields.endDate;
  }

  setEndDate(value) {
    this.fields.endDate = value;
  }

  buildFromInternalJson(json) {
    this.fields = { ...this.fields, ...json };
    return this;
  }

  generateInternalJson() {
    return this.fields;
  }
}
