import GuestRepo from '../Guests/Guests.repo';
import ServiceRepo from '../Services/Services.repo';
import RoomRepo from '../Rooms/Rooms.repo';
import Guest from '../Guests/Guest.entity';
import Room from '../Rooms/Room.entity';
import Service from '../Services/Service.entity';
import Search from '../Search/Search.object';
import SupplierCode from './SupplierCode.object';

const ATD_CONFIRMATION_TYPE_ID = '14';

export default class Order {
  constructor() {
    this.fields = {
      resGlobalInfo: null,
      orderId: null,
      supplier: null,
      atdHotelId: null,

      /** @type {null | string[]} */
      orderTicketIds: null,
      ibsReservationStatus: null,
      atdOrderStatus: null,
      supplierCodes: [],
    };

    this.guestsRepo = new GuestRepo();
    this.servicesRepo = new ServiceRepo();
    this.roomsRepo = new RoomRepo();
    this.search = new Search();
  }

  getOrderId() {
    return this.fields.orderId;
  }

  setOrderId(value) {
    this.fields.orderId = value;
  }

  setOrderTicketIds(value) {
    this.fields.orderTicketIds = value;
  }

  getOrderTicketIds() {
    return this.fields.orderTicketIds;
  }

  /**
   *
   * @returns {SupplierCode[]}
   */
  getSupplierCodes() {
    return this.fields.supplierCodes.map((codeJson) => (
      SupplierCode.buildFromJson(codeJson)
    ));
  }

  getAtdReservationId() {
    let reservationId;
    const foundResId = this.fields.resGlobalInfo.hotelReservationIds.hotelReservationId.some((resObj) => {
      if (resObj.type === ATD_CONFIRMATION_TYPE_ID) {
        reservationId = resObj.value;
        return true;
      }
      return false;
    });
    if (foundResId && reservationId) { return reservationId; }

    console.log(`Could not find type: ${ATD_CONFIRMATION_TYPE_ID} reservation id, unable to perform enhanced search`);
    return null;
  }

  getSupplier() {
    return this.fields.supplier;
  }

  setSupplier(value) {
    // js note: doing == null also checks for undefined
    if (value == null) {
      throw new Error('Order Entity: supplier field attempted to be set to a null or undefined value');
    }
    this.fields.supplier = value;
  }

  getAtdHotelId() {
    return this.fields.atdHotelId;
  }

  setAtdHotelId(value) {
    if (value == null) { throw new Error('Order Entity: atdHotelId field attempted to be set to a null or undefined value'); }
    this.fields.atdHotelId = value;
  }

  getAtdOrderStatus() {
    return this.fields.atdOrderStatus;
  }

  setAtdOrderStatus(value) {
    this.fields.atdOrderStatus = value;
  }

  /**
   *
   * @param {Order} order
   */
  buildFromObject(order) {
    const json = order.generateInternalJson();
    this.fields = { ...this.fields, ...json };
    this.guestsRepo.buildFromInternalJson(json);
    this.servicesRepo.buildFromInternalJson(json, this.guestsRepo);
    this.roomsRepo.buildFromObject(order.getRoomsRepo(), this.guestsRepo, this.servicesRepo);
    this.search.buildFromInternalJson(json.search);
    return this;
  }

  buildFromExternalJson(modJson) {
    // const order = new Order(modData);
    this.guestsRepo.buildFromExternalJson(modJson);
    this.servicesRepo.buildFromExternalJson(modJson, this.guestsRepo);
    this.roomsRepo.buildFromExternalJson(modJson, this.guestsRepo, this.servicesRepo);

    const { resGlobalInfo, resStatus } = modJson;

    this.fields.resGlobalInfo = resGlobalInfo;
    this.fields.ibsReservationStatus = resStatus;

    // to be moved to ibs supplier specific function
    if ('hotelReservationIds' in resGlobalInfo) {
      resGlobalInfo.hotelReservationIds.hotelReservationId.forEach((confCode) => {
        if ('type' in confCode && 'value' in confCode) {
          let confTitle = '';
          switch (confCode.type) {
            case '8':
              confTitle = 'IBS Confirmation (8)';
              break;
            case '14':
              confTitle = 'IBS ATD ID (14)';
              break;
            case '18':
              confTitle = 'IBS 3rd Party Confirmation (18)';
              break;
            default:
              confTitle = confCode.type;
              break;
          }
          this.fields.supplierCodes.push(
            new SupplierCode(confTitle, confCode.value).generateJson(),
          );
        }
      });
    }
    return this;
  }

  buildFromInternalJson(json) {
    this.fields = { ...this.fields, ...json };
    this.guestsRepo.buildFromInternalJson(json);
    this.servicesRepo.buildFromInternalJson(json, this.guestsRepo);
    this.roomsRepo.buildFromInternalJson(json, this.guestsRepo, this.servicesRepo);
    this.search.buildFromInternalJson(json.search);
    return this;
  }

  getGuestsRepo() {
    return this.guestsRepo;
  }

  /**
   *
   * @returns {Guest[]}
   */
  getAllGuests() {
    return this.guestsRepo.getAllElements();
  }

  getRoomsRepo() {
    return this.roomsRepo;
  }

  /**
   *
   * @returns {Room[]}
   */
  getAllRooms() {
    return this.roomsRepo.getAllElements();
  }

  getServicesRepo() {
    return this.servicesRepo;
  }

  /**
   *
   * @returns {Object<Service[]>}
   */
  getAllServices() {
    return this.servicesRepo.getAllElements();
  }

  /**
   *
   * @param {ServiceRepo} services
   */
  setServicesRepo(services) {
    this.servicesRepo = services;
  }

  /**
   *
   * @param {RoomRepo} rooms
   */
  setRoomsRepo(rooms) {
    this.roomsRepo = rooms;
  }

  /**
   * @param {Search} value
   */
  setSearch(value) {
    this.search = value;
  }

  getIbsReservationStatus() {
    return this.fields.ibsReservationStatus;
  }

  generateInternalJson() {
    return {
      ...this.fields,
      guests: this.guestsRepo.generateInternalJson(),
      rooms: this.roomsRepo.generateInternalJson(),
      services: this.servicesRepo.generateInternalJson(),
      search: this.search.generateInternalJson(),
    };
  }
}
