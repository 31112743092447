import React from 'react';
import styles from './DisplayOrderSection.module.css';

function DisplaySection({
  children, direction, displayTitle, rootClass,
}) {
  const sectionStyle = {};
  if (direction != null) {
    sectionStyle.display = 'flex';
    sectionStyle.flexDirection = direction;
  }
  return (
    <div className={rootClass}>
      {displayTitle}
      <div style={sectionStyle}>{children}</div>
    </div>
  );
}

/**
 *
 * @param {{title: string, children: React.ReactChildren, direction: string}} props
 */
export function DisplayOrderSection({ title, children, direction }) {
  let displayTitle = '';
  if (title != null) {
    displayTitle = <h3 style={{ borderBottom: '1px solid #c5c5c5' }}>{title}</h3>;
  }

  return (
    <DisplaySection
      direction={direction}
      rootClass={styles.section}
      displayTitle={displayTitle}
    >
      {children}
    </DisplaySection>
  );
}

/**
 * @param {{title: string, children: React.ReactChildren}} props
 */
export function DisplaySubSection({ title, children, direction }) {
  let displayTitle = '';
  if (title != null) {
    displayTitle = <h5>{title}</h5>;
  }

  return (
    <DisplaySection
      direction={direction}
      rootClass={styles.subSection}
      displayTitle={displayTitle}
    >
      {children}
    </DisplaySection>
  );
}
