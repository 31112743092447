import {
  extractRoomGuests, extractRoomServices, removeGuest, removeRoom, removeService,
} from '../../Shared/Domain/DomainServices/DomainServices';
import { EnvironmentValue } from '../../Context/EnvironmentContext';
import { buildAvailabilityObject, updateOrderUsingAvailability } from '../../Services/LoadAvailability.service';
import Guest from '../../Shared/Domain/Guests/Guest.entity';
import Order from '../../Shared/Domain/Order/Order.entity';
import Room from '../../Shared/Domain/Rooms/Room.entity';
import RoomRepo from '../../Shared/Domain/Rooms/Rooms.repo';
import Service from '../../Shared/Domain/Services/Service.entity';
import ServiceRepo from '../../Shared/Domain/Services/Services.repo';

export const ibsOrderDataActions = {
  FAIL_OPERATION: 'FAIL_OPERATION',
  SEND_ORDER: 'SEND_ORDER',
  LOAD_ORDER: 'LOAD_ORDER',
  SEND_AVAILABILITY: 'SEND_AVAILABILITY',
  LOAD_AVAILABILITY: 'LOAD_AVAILABILITY',
  SEND_MODIFICATION: 'SEND_MODIFICATION',
  LOAD_MODIFICATION: 'LOAD_MODIFICATION',
  EDIT_MULTIPLE_ORDER_ELEMENTS: 'EDIT_MULTIPLE_ORDER_ELEMENTS',
  REMOVE_ORDER_ELEMENT: 'REMOVE_ORDER_ELEMENT',
  UPDATE_SEARCH: 'UPDATE_SEARCH',
  UPDATE_ORDER: 'UPDATE_ORDER',
  CHANGE_ENV: 'CHANGE_ENV',
};

/**
 * @typedef {{
   * availability: Object,
   * order: Order,
   * action: {type: string, data: any},
   * orderDetails: {orderId: string, orderTicketIds: string[]},
   * orderEnvironmentId: string
   * notifications: []
 * } State
 */

/**
 * @type {State}
 */
export const originalState = {
  availability: null,
  order: null,
  action: getIdleAction(),
  orderDetails: { orderId: null, orderTicketIds: null },
  orderEnvironmentId: 'stage4',
  notifications: [],
};

/**
 *
 * @param {*} initialOrderContext
 * @param {EnvironmentValue} appEnvironment
 * @returns
 */

export function buildInitialState(initialOrderContext, appEnvironment) {
  let newState = originalState;
  if (initialOrderContext.orderId != null) {
    newState = { ...originalState };
    newState.orderDetails.orderId = initialOrderContext.orderId;
    // will default to stage4 for standalone
    if (appEnvironment.fixedOrderEnv != null) {
      newState.orderEnvironmentId = appEnvironment.fixedOrderEnv;
    }
    newState.action.type = ibsOrderDataActions.SEND_ORDER;
  }
  return newState;
}

/**
 *
 * @param {State} pipelineState
 * @param {*} responseOrder
 * @param {[]} newNotifications
 * @returns {State}
 */
function buildUpdatedStateOnOrderLoad(pipelineState, responseOrder, newNotifications) {
  const updatedState = { ...pipelineState };
  if (!(responseOrder instanceof Error)) {
    const loadedOrder = new Order().buildFromInternalJson(responseOrder);
    updatedState.order = loadedOrder;
  }
  // add new notifications to array
  const notifications = [...pipelineState.notifications, ...newNotifications];
  updatedState.notifications = notifications;

  return updatedState;
}

function buildUpdatedStateOnAvailabilityLoad(pipelineState, responseAvailability, newNotifications) {
  const updatedState = { ...pipelineState };

  if (!(responseAvailability instanceof Error)) {
    const availability = buildAvailabilityObject(pipelineState.order, responseAvailability);
    const newOrder = updateOrderUsingAvailability(pipelineState.order, availability);

    updatedState.order = newOrder;
    updatedState.availability = availability;
  }

  const notifications = [...pipelineState.notifications, ...newNotifications];
  updatedState.notifications = notifications;

  return updatedState;
}

/**
 *
 * @param {*} state
 * @param {*} data
 * @returns
 */
const loadOrder = (state, data) => {
  const responseOrder = data.order;
  const responseAvailability = data.availability;
  const responseNotifications = data.notifications;

  // treat creating new state as pipeline:
  //  state change on order load -> use this state to pipe into build state for availability loading
  let newState = buildUpdatedStateOnOrderLoad(state, responseOrder, responseNotifications);
  if (responseAvailability) {
    newState = buildUpdatedStateOnAvailabilityLoad(newState, responseAvailability, responseNotifications);
  }

  return {
    // original state
    ...state,
    // overwrite (matching properties) with new state
    ...newState,
    action: getIdleAction(),
  };
};

/**
 *
 * @param {State} state
 * @param {*} data
 * @returns
 */
function loadAvailability(state, data) {
  const responseAvailability = data.availability;
  const responseNotifications = data.notifications;

  const newState = buildUpdatedStateOnAvailabilityLoad(state, responseAvailability, responseNotifications);

  return {
    ...state,
    ...newState,
    action: getIdleAction(),
  };
}

function failOperation(state, data) {
  return {
    ...state,
    notifications: [...state.notifications, data],
    action: getIdleAction(),
  };
}

function loadModification(state, data) {
  const { modification, notifications } = data;

  const newNotifications = [...state.notifications, ...notifications];
  return {
    ...state,
    notifications: newNotifications,
    action: getIdleAction(),
  };
}

/**
   *
   * @param {State} state
   * @param {[]} editedElement
   * @returns
   */
const editOrderElements = (initialState, editedElement) => {
  const newState = { ...initialState };
  const processedNewState = editedElement.reduce((state, element) => {
    if (element instanceof Guest || element instanceof Room || element instanceof Service) {
      if (element instanceof Guest) {
        state.order.getGuestsRepo().setElement(element.getInternalId(), element);
        state.order.getGuestsRepo().renumberElements();
      }
      if (element instanceof Room) {
        state.order.getRoomsRepo().setElement(element.getInternalId(), element);
        state.order.getRoomsRepo().renumberElements();
      }
      if (element instanceof Service) {
        state.order.getServicesRepo().setElement(element.getInternalId(), element);
        state.order.getServicesRepo().renumberElements();
      }
      return state;
    }
    throw new Error(`Unrecognised element passed of type: ${element.constructor.name}`);
  }, newState);

  processedNewState.order = new Order().buildFromObject(processedNewState.order);
  return processedNewState;
};

/**
   * @param {State} state
   * @param {*} data
   * @returns
   */
const removeOrderElement = (state, data) => {
  const newState = { ...state };
  if (data instanceof Guest || data instanceof Room || data instanceof Service) {
    if (data instanceof Guest) {
      removeGuest(data.getInternalId(), state.order.getRoomsRepo(), state.order.getServicesRepo(), state.order.getGuestsRepo());
    }
    if (data instanceof Room) {
      removeRoom(data.getInternalId(), state.order.getRoomsRepo(), state.order.getServicesRepo(), state.order.getGuestsRepo());
    }
    if (data instanceof Service) {
      removeService(data.getInternalId(), state.order.getRoomsRepo(), state.order.getServicesRepo());
    }
    newState.order = new Order().buildFromObject(state.order);
    return newState;
  }
  throw new Error(`Unrecognised data passed of type: ${data.constructor.name}`);
};

/**
   * @param {State} state
     * @param {Search} data
     */
const updateSearch = (state, data) => {
  state.order.setSearch(data);
  const newState = { ...state };
  newState.order = new Order().buildFromObject(state.order);
  return newState;
};

/**
   *
   * @param {State} state
   * @param {Order} data
   * @returns
   */
const updateOrder = (state, data) => {
  const newState = { ...state };
  newState.order = new Order().buildFromObject(data);
  return newState;
};

function getIdleAction() {
  return { type: null, data: null };
}

/**
 *
 * @param {State} state
 * @param {*} action
 * @returns
 */
const orderStateReducer = (state, action) => {
  const data = action.data;
  switch (action.type) {
    case ibsOrderDataActions.FAIL_OPERATION:
      return failOperation(state, data);
    case ibsOrderDataActions.SEND_ORDER:
      return {
        ...originalState,
        orderEnvironmentId: state.orderEnvironmentId,
        orderDetails: data,
        action,
      };
    case ibsOrderDataActions.LOAD_ORDER:
      return loadOrder(state, data);
    case ibsOrderDataActions.SEND_AVAILABILITY:
      return {
        ...state,
        availability: null,
        action,
      };
    case ibsOrderDataActions.LOAD_AVAILABILITY:
      return loadAvailability(state, data);
    case ibsOrderDataActions.SEND_MODIFICATION:
      return { ...state, action };
    case ibsOrderDataActions.LOAD_MODIFICATION:
      return loadModification(state, data);
    case ibsOrderDataActions.CHANGE_ENV:
      return { ...state, orderEnvironmentId: data };
    // Edit state of form
    case ibsOrderDataActions.EDIT_MULTIPLE_ORDER_ELEMENTS:
      return editOrderElements(state, data);
    case ibsOrderDataActions.REMOVE_ORDER_ELEMENT:
      return removeOrderElement(state, data);
    case ibsOrderDataActions.UPDATE_SEARCH:
      return updateSearch(state, data);
    case ibsOrderDataActions.UPDATE_ORDER:
      return updateOrder(state, data);

    default:
      throw new Error('IBS Order no action given');
  }
};

export default orderStateReducer;
