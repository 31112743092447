/* eslint-disable no-unused-vars */
import { addUniqueValuesToArray, getSafely, removeValuesFromArray } from '../../util';
import Guest from '../Guests/Guest.entity';
import GuestRepo from '../Guests/Guests.repo';

const pricingTypes = {
  PER_STAY: 'Per stay',
  PER_PERSON: 'Per person',
  PER_PERSON_PER_NIGHT: 'Per person per night',
};

function buildHistoryStateInFriendlyString(historyState) {
  if (historyState == null) { return ''; }

  // eslint-disable-next-line no-use-before-define
  return new Service()
    .buildUsingOnlyFields(historyState)
    .buildOptionKey();
}

function buildPrice(amountBeforeTax, amountAfterTax, minAge, maxAge) {
  return [{
    base: null,
    total: {
      amountBeforeTax,
      amountAfterTax,
    },
    minAge,
    maxAge,
  }];
}

/**
 * @param {string} inventoryCode
 */
function extractBlockCodeFromInventoryCode(inventoryCode) {
  const invCodeParts = inventoryCode.split('_');
  if (invCodeParts.length < 3) { throw new Error('Could not get block code from service'); }
  return invCodeParts[2];
}

function getBlankFields() {
  return {
    id: undefined,
    supplierId: undefined,
    guestIds: [],
    inventoryCode: undefined,
    quantity: undefined,
    inclusive: undefined,
    pricingType: undefined,
    startDate: undefined,
    endDate: undefined,
    price: undefined,
    blockCode: undefined,
    friendlyName: null,
  };
}

function extractGuestIdsFromExternalJson(service) {
  let guestIds = [];
  if (Array.isArray(service.serviceDetails.resGuestRphs)) {
    guestIds = service.serviceDetails.resGuestRphs.map(
      (resGuestRphsElement) => (resGuestRphsElement.resGuestRph.rph),
    );
  }
  return guestIds;
}

export default class Service {
  constructor() {
    this.fields = getBlankFields();
    this.delete = false;
    this.fieldsHistory = [];
    this.pricingTypes = pricingTypes;
  }

  // *****************
  // IMPORT
  // *****************

  buildFromExternalJson(id, service) {
    this.setInternalId(id);
    this.fields.supplierId = service.serviceRph;
    this.fields.inventoryCode = service.serviceInventoryCode;
    this.fields.quantity = service.quantity;
    this.fields.inclusive = service.inclusive;
    this.fields.pricingType = service.servicePricingType;
    this.fields.startDate = service.serviceDetails.timeSpan.start;
    this.fields.endDate = service.serviceDetails.timeSpan.end;
    this.fields.guestIds = extractGuestIdsFromExternalJson(service);
    this.fields.price = service.price;
    this.fields.blockCode = service.inventoryBlockCode;

    // TODO test all required fields are not undefined
    return this;
  }

  buildFromAvailabilityJson(id, addOn, priceElement) {
    this.setInternalId(id);
    this.fields.inventoryCode = addOn.addon_code;
    this.fields.blockCode = extractBlockCodeFromInventoryCode(this.fields.inventoryCode);
    this.fields.quantity = addOn.quantity;
    this.fields.inclusive = addOn.inclusive;
    this.fields.pricingType = addOn.price_model;
    // TODO look at how to deal with ones that need single-date etc
    this.fields.startDate = addOn.start_date;
    this.fields.endDate = addOn.end_date;
    // this.fields.price = [priceElement];
    this.fields.price = buildPrice(
      priceElement.total.amount_before_tax,
      priceElement.total.amount_after_tax,
      priceElement.min_age,
      priceElement.max_age,
    );

    return this;
  }

  /**
   *
   * @param {*} json
   * @param {GuestRepo} allGuests
   * @returns
   */
  buildFromInternalJson(json) {
    this.fields = { ...this.fields, ...json };
    return this;
  }

  /**
   *
   * @param {Service} avService
   * @returns {Service}
   */
  buildFromSelectedAvailability(avService) {
    const newService = new Service().buildFromInternalJson(
      avService.generateInternalJson(),
    );
    newService.setGuestIds(this.getGuestIds());
    newService.setInternalId(this.getInternalId());
    newService.setSupplierId(this.getSupplierId());

    return newService;
  }

  buildUsingOnlyFields(json) {
    this.fields = { ...this.fields, ...json };
    return this;
  }

  // ----------------------------

  isMatchingService(service) {
    if (JSON.stringify(this.generateIdentifierInformation())
    === JSON.stringify(service.generateIdentifierInformation())) {
      return true;
    }
    return false;
  }

  resetForAvailability() {
    const newFields = getBlankFields();
    const id = this.getInternalId();
    const guests = this.getGuestIds();

    this.fields = newFields;

    this.setInternalId(id);
    this.setGuestIds(guests);
  }

  /**
   * @param {Service} prevService
   */
  setDatesBasedOnPricingType(prevService) {
    const pricingType = this.getPricingType();
    if (pricingType == null) {
      // likely a new blank service
    } else if (pricingType === pricingTypes.PER_STAY) {
      this.setEndDate(this.getStartDate());
    } else if (pricingType === pricingTypes.PER_PERSON) {
      this.setStartDate(prevService.getStartDate());
      this.setEndDate(prevService.getEndDate());
    } else if (pricingType === pricingTypes.PER_PERSON_PER_NIGHT) {
      // keep the same
    }
  }

  /**
   *
   * @param {Guest[]} roomGuests
   */
  setGuestsBasedOnPricingType(roomGuests) {
    const pricingType = this.getPricingType();
    if (pricingType == null) {
      // likely a new blank service
    } else if (pricingType === pricingTypes.PER_STAY) {
      // set to first guest in room
      if (getSafely(() => roomGuests[0])) {
        const firstGuestId = (roomGuests[0]).getInternalId();
        this.setGuestIds([firstGuestId]);
      }
    } else if ([pricingTypes.PER_PERSON, pricingTypes.PER_PERSON_PER_NIGHT].includes(pricingType)) {
      // this.setAllAgeAppropriateGuests(roomGuests);
    }
  }

  getHistoryStateByIndex(index) {
    // stack: 0 is current, 1 is previous etc.
    return getSafely(() => this.fieldsHistory[index]);
  }

  getPreviousStateAsFriendlyString() {
    return buildHistoryStateInFriendlyString(this.getHistoryStateByIndex(1));
  }

  getOriginalStateAsFriendlyString() {
    const index = this.fieldsHistory.length - 1;
    if (index <= 1) { return ''; }
    return buildHistoryStateInFriendlyString(this.getHistoryStateByIndex(index));
  }

  buildOptionKey() {
    let ageRange = '';
    const friendlyName = `${this.getFriendlyName() || '*'}`;
    if (this.getMinAge() && this.getMaxAge()) { ageRange = ` [${this.getMinAge()} - ${this.getMaxAge()}]`; }
    return `${friendlyName} ${this.getInventoryCode()}${ageRange}`;
  }

  getInternalId() {
    return this.fields.id;
  }

  getSupplierId() {
    return this.fields.supplierId;
  }

  setSupplierId(value) {
    this.fields.supplierId = value;
  }

  setInternalId(value) {
    this.fields.id = value;
  }

  /**
   *
   * @returns {String[]}
   */
  getGuestIds() {
    return this.fields.guestIds;
  }

  /**
   *
   * @returns {String[]}
   */
  setGuestIds(value) {
    const uniqueIds = addUniqueValuesToArray([], ...value);
    this.fields.guestIds = uniqueIds;
  }

  removeGuestId(value) {
    const ids = removeValuesFromArray(this.getGuestIds(), value);
    this.setGuestIds(ids);
  }

  getPricingType() {
    return this.fields.pricingType;
  }

  getPrice() {
    return this.fields.price;
  }

  getStartDate() {
    return this.fields.startDate;
  }

  setStartDate(value) {
    this.fields.startDate = value;
  }

  getEndDate() {
    return this.fields.endDate;
  }

  setEndDate(value) {
    this.fields.endDate = value;
  }

  getQuantity() {
    return this.fields.quantity;
  }

  getInclusive() {
    return this.fields.inclusive;
  }

  getInventoryCode() {
    return this.fields.inventoryCode;
  }

  getBlockCode() {
    return this.fields.blockCode;
  }

  getMinAge() {
    if (this.fields.price) {
      return this.fields.price[0].minAge;
    }
    return false;
  }

  getMaxAge() {
    if (this.fields.price) {
      return this.fields.price[0].maxAge;
    }
    return false;
  }

  getCostBeforeTax() {
    if (this.fields.price) { return this.fields.price[0].total.amountBeforeTax; }
    return undefined;
  }

  getCostAfterTax() {
    if (this.fields.price) { return this.fields.price[0].total.amountAfterTax; }
    return undefined;
  }

  getFriendlyName() {
    return this.fields.friendlyName;
  }

  setFriendlyName(value) {
    this.fields.friendlyName = value;
  }
  // *****************
  // EXPORT
  // *****************

  generateIdentifierInformation() {
    return {
      inventoryCode: this.getInventoryCode(),
    };
  }

  generateInternalJson() {
    // could check all are assigned
    return this.fields;
  }
}
