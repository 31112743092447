/* eslint-disable class-methods-use-this */

import AuthenticationValue from '../Domain/AuthenticationValue';
import { EnvironmentValue } from '../Context/EnvironmentContext';
import BackendError from '../Shared/Domain/Error/BackendError';
import RequestBody from '../Shared/Domain/RequestBody/RequestBody.object';
import ResponseBody from '../Shared/Domain/ResponseBody/ResponseBody.object';

function buildResponseObj(json) {
  return new ResponseBody().buildFromJson(json);
}

function buildDefaultHeaders(authenticationContext) {
  const apiSid = authenticationContext.getApiSid();
  const defaultHeaders = {
    'Content-Type': 'application/json',
  };
  if (apiSid != null) {
    defaultHeaders.Authorization = `Basic ${authenticationContext.getApiSid()}`;
  }
  return defaultHeaders;
}

export default class OrderDataService {
  /**
   *
   * @param {string} orderEnvironmentId
   * @param {AuthenticationValue} authenticationContext
   * @param {EnvironmentValue} appEnvironment
   */
  constructor(orderEnvironmentId, authenticationContext, appEnvironment) {
    this.orderEnvironmentId = orderEnvironmentId;
    this.authenticationContext = authenticationContext;
    this.defaultHeaders = buildDefaultHeaders(this.authenticationContext);
    this.backendDomain = appEnvironment.getBackendDomain();
  }

  buildBodyJson(contents) {
    const body = new RequestBody();
    body.setConfig(this.orderEnvironmentId);
    if (contents) body.setData(contents);
    return body.generateJsonBody();
  }

  /**
 * @param {Object<string,*>} obj
 * @returns {string}
 */
  buildQueryString(obj) {
    if (obj) {
      return Object.entries(obj).map(
        ([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`,
      ).join('&');
    }
    return '';
  }

  /**
 * @param {string} orderId
 * @param {string[]} orderTicketIds
  */
  async getOrderDataPromise(orderId) {
    let response = await fetch(`${this.backendDomain}/api/readmod/${orderId}`, {
      method: 'post',
      headers: this.defaultHeaders,
      body: this.buildBodyJson(),
    });
    response = await response.json();
    return buildResponseObj(response);
  }

  /**
   * @param {object} fields
   */
  async getAvailability(fields, options) {
    const queryString = this.buildQueryString(options);

    const response = await fetch(`${this.backendDomain}/api/availability?${queryString}`, {
      method: 'post',
      headers: this.defaultHeaders,
      body: this.buildBodyJson(fields),
    });
    const responseJson = await response.json();
    return buildResponseObj(responseJson);
  }

  /**
   * @param {string} orderId
   * @param {string[]} orderTicketIds
   * @param {object} fields
   */
  async sendUpdatedOrder(orderId, orderTicketIds, fields) {
    const sendBody = {
      orderTicketIds,
      data: fields,
    };

    let response = await fetch(`${this.backendDomain}/api/testwritemod/${orderId}`, {
      method: 'post',
      headers: this.defaultHeaders,
      body: this.buildBodyJson(sendBody),
    });
    response = await response.json();
    return buildResponseObj(response);
  }
}
