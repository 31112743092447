import React, { useMemo } from 'react';
import environmentSettings from '../Config/EnvironmentSettings';

export const EnvironmentContext = React.createContext();

export class EnvironmentValue {
  constructor(envId) {
    this.envId = envId;
    this.foundEnvSettings = false;
    this.backendDomain = null;
    this.fixedOrderEnv = null;
  }

  hasFoundEnvironmentSettings() {
    return this.foundEnvSettings;
  }

  buildFromSettingsJson() {
    Object.values(environmentSettings).forEach((env) => {
      if (env.id === this.envId) {
        this.foundEnvSettings = true;
        this.backendDomain = env.backendDomain;
        this.atdDomain = env.atdDomain;
        this.fixedOrderEnv = env.fixedOrderEnv;
      }
    });
    return this;
  }

  getBackendDomain() {
    return this.backendDomain;
  }

  getAtdDomain() {
    return this.atdDomain;
  }
}

export const EnvironmentContextProvider = ({ children }) => {
  const appEnvironment = useMemo(() => (
    window.hotel_modification_app_env
  ), [window.hotel_modification_app_env]);

  const environmentValue = new EnvironmentValue(appEnvironment).buildFromSettingsJson();

  let display;

  if (environmentValue.hasFoundEnvironmentSettings()) {
    display = (
      <EnvironmentContext.Provider value={environmentValue}>
        {children}
      </EnvironmentContext.Provider>
    );
  } else {
    display = <p>App Environment Mode is undefined or not supported.</p>;
  }

  return display;
};
