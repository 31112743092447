/**
 * @template T
 * @param {T} func
 * @param {Object} thisContext
 * @returns {T}
 */
export const bindFunction = (func, thisContext) => func.bind(thisContext);

/**
 * @template T
 * @template T2
 * @param {T[]} array
 * @param {(element:T)=>T2 | false} callback
 * @returns {T2[]}
 */
export const mapIfNotFalse = (array, callback) => {
  /** @type {T2[]} */
  const returnArray = [];
  array.forEach((element) => {
    const returnValue = callback(element);
    if (returnValue) { returnArray.push(returnValue); }
  });
  return returnArray;
};

/**
 *
 * @param {*} fn
 * @returns {any | undefined}
 */
export const getSafely = (fn) => {
  let returnVal;
  try {
    returnVal = fn();
  } catch (e) {
    returnVal = undefined;
  }
  return returnVal;
};

export function getClassProperties(classInstance) {
  const properties = Object.getOwnPropertyNames(classInstance);
  const returnProps = {};
  properties.forEach((prop) => {
    returnProps[prop] = classInstance[prop];
  });
  return returnProps;
}

/**
 * @param {*[]} array
 * @param {*[]} values
 */
export function removeValuesFromArray(array, ...values) {
  const returnArray = array.filter((val) => !(values.includes(val)));
  return returnArray;
}

/**
 * @param {*[]} array
 * @param {*[]} values
 */
export function addUniqueValuesToArray(array, ...values) {
  const set = new Set([...array, ...values]);
  return Array.from(set);
}
