import React from 'react';
import PropTypes from 'prop-types';
import ServiceRepo from '../../Shared/Domain/Services/Services.repo';
import Service from '../../Shared/Domain/Services/Service.entity';
import DisplayGuestReference from './DisplayGuestReference';
import GuestRepo from '../../Shared/Domain/Guests/Guests.repo';
import {
  Button, DatePicker, MultiSelectInput, SelectInput, TextInput,
} from '../FormElements/FormElements';
import { DisplayOrderSection, DisplaySubSection } from './DisplayOrderSection';
import { MultiGuestSelect } from './DisplayGuests';
import { mapIfNotFalse } from '../../Shared/util';
import Guest from '../../Shared/Domain/Guests/Guest.entity';

/**
 * @typedef {{
 *  serviceRepo:ServiceRepo,
 *  guestRepo: GuestRepo,
 *  changeOrderElement: Function,
 *  removeOrderElement: Function,
 *  availabilityServiceRepo: ServiceRepo
 *  addNewService: Function,
 *  guests: Guest[],
 *  services: Service[]
 * }} Props
 */
class DisplayServicesConfig {
  /**
   * @param {Props}
   */
  constructor({
    guestRepo, serviceRepo, changeOrderElement, removeOrderElement, availabilityServiceRepo, addNewService, guests, services,
  }) {
    this.guestRepo = guestRepo;
    this.serviceRepo = serviceRepo;
    this.changeOrderElement = changeOrderElement;
    this.removeOrderElement = removeOrderElement;
    this.availabilityServiceRepo = availabilityServiceRepo;
    this.addNewService = addNewService;
    this.guests = guests;
    this.services = services;
  }
}

/**
 * @param {Props} props
 */
function DisplayServices(props) {
  const {
    serviceRepo, addNewService, services,
  } = props;

  const displayServicesConfig = new DisplayServicesConfig(props);
  const display = services.map((service) => (
    <DisplayService key={service.getSupplierId()} service={service} displayServicesConfig={displayServicesConfig} />
  ));
  return (
    <DisplayOrderSection title="Services">
      {display}
      <Button onClick={(e) => { addNewService(serviceRepo.createNewElement()); }}>Add Service</Button>
    </DisplayOrderSection>
  );
}

/**
 *
 * @param {{displayServicesConfig: DisplayServicesConfig, service: Service}} props
 */
const DisplayServicesGuests = ({ service, displayServicesConfig }) => {
  const { guestRepo, changeOrderElement, guests } = displayServicesConfig;

  function updateAssociatedGuests(e) {
    /** @type {string[]} */
    const guestIds = e.target.value;
    service.setGuestIds(guestIds);
    changeOrderElement(service);
  }
  const display = <MultiGuestSelect onChange={updateAssociatedGuests} guests={guests} initialValueIds={service.getGuestIds()} />;

  return (
    <div>
      <h6>Guests</h6>
      {display}
    </div>
  );
};

/**
 *
 * @param {{service: Service, displayServicesConfig: DisplayServicesConfig}} props
 */
function DisplayService({
  service, displayServicesConfig,
}) {
  const {
    changeOrderElement, availabilityServiceRepo, guestRepo, removeOrderElement, guests,
  } = displayServicesConfig;

  function handleChange(callback) {
    return (e) => {
      callback(e.target.value);
      changeOrderElement(service);
    };
  }

  const serviceOptions = {};
  if (availabilityServiceRepo != null) {
    availabilityServiceRepo.getAllElements().forEach((avService) => {
      serviceOptions[avService.buildOptionKey()] = avService.getInternalId();
    });
  }

  serviceOptions[service.buildOptionKey()] = 'current';

  function handleServiceChange(e) {
    const serviceId = e.target.value;
    const availabilityService = availabilityServiceRepo.getElement(serviceId);
    const newService = service.buildFromSelectedAvailability(availabilityService, guestRepo);
    newService.fieldsHistory = [newService.generateInternalJson(), service.generateInternalJson(), ...service.fieldsHistory];

    newService.setDatesBasedOnPricingType(service);
    newService.setGuestsBasedOnPricingType(guests);
    changeOrderElement(newService);
  }

  const originalStateString = service.getOriginalStateAsFriendlyString();
  const prevStateString = service.getPreviousStateAsFriendlyString();

  // eslint-disable-next-line react/jsx-one-expression-per-line
  const originalStateDisplay = originalStateString ? (<p>Orig: {originalStateString}</p>) : null;

  // eslint-disable-next-line react/jsx-one-expression-per-line
  const prevStateDisplay = prevStateString ? (<p>Prev: {prevStateString}</p>) : null;

  return (
    <DisplaySubSection title={`Service ${service.getSupplierId()}`}>
      {originalStateDisplay}
      {prevStateDisplay}
      <SelectInput onChange={handleServiceChange} options={serviceOptions} value="current" />
      <p>{`${service.getCostBeforeTax()} / ${service.getCostAfterTax()} after tax`}</p>
      <p>{service.getPricingType()}</p>
      <DatePicker onChange={handleChange((val) => service.setStartDate(val))} value={service.getStartDate()} />
      <DatePicker onChange={handleChange((val) => service.setEndDate(val))} value={service.getEndDate()} />
      <DisplayServicesGuests service={service} displayServicesConfig={displayServicesConfig} />
      <Button onClick={() => { removeOrderElement(service); }}>Remove Service</Button>
    </DisplaySubSection>
  );
}
DisplayService.propTypes = {
  service: PropTypes.instanceOf(Service).isRequired,
  guestRepo: PropTypes.instanceOf(GuestRepo).isRequired,
};

/**
 * @param {{serviceRepo: ServiceRepo, limitIds: String[], onChange; Function}} props
 */
export const MultiServiceSelect = ({ serviceRepo, limitIds, onChange }) => {
  const limitedOptions = {};
  serviceRepo.getAllElements().forEach((service) => {
    limitedOptions[`#${service.getSupplierId()}: ${service.getInventoryCode()}`] = service.getInternalId();
  });
  const display = <MultiSelectInput onChange={(e) => { onChange(e); }} options={limitedOptions} value={limitIds} />;

  return display;
};

export default DisplayServices;
