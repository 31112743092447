import {
  extractRoomGuests, extractRoomServices, extractServiceGuests, guestsAreWithinAgeRange,
} from '../Shared/Domain/DomainServices/DomainServices';
import GuestRepo from '../Shared/Domain/Guests/Guests.repo';
import Order from '../Shared/Domain/Order/Order.entity';
import Room from '../Shared/Domain/Rooms/Room.entity';
import RoomRepo from '../Shared/Domain/Rooms/Rooms.repo';
import Service from '../Shared/Domain/Services/Service.entity';
import ServiceRepo from '../Shared/Domain/Services/Services.repo';
import { getSafely } from '../Shared/util';

/**
 *
 * @param {Order} order
 * @param {*} availability
 * @returns
 */
export function updateOrderUsingAvailability(order, availability) {
  const newOrderRooms = new RoomRepo();
  const newOrderServices = new ServiceRepo();

  order.getRoomsRepo().getAllElements().forEach((room, roomIndex) => {
    const avRoomRepo = getSafely(() => availability.rooms[roomIndex]);
    const avServiceRepo = getSafely(() => availability.services[roomIndex]);

    const newRoom = updateOrderRoomUsingAvailability(room, avRoomRepo, order.getGuestsRepo(), order.getServicesRepo());
    const newRoomServices = updateOrderRoomServicesUsingAvailability(room, avServiceRepo, order.getGuestsRepo(), order.getServicesRepo());

    newOrderRooms.setElement(newRoom.getInternalId(), newRoom);
    newRoomServices.forEach((service) => {
      newOrderServices.setElement(service.getInternalId(), service);
    });
  });
  const newOrder = new Order().buildFromObject(order);
  newOrder.setRoomsRepo(newOrderRooms);
  newOrder.setServicesRepo(newOrderServices);
  return newOrder;
}

/**
 *
 * @param {*} room
 * @param {*} releventAvailability
 * @param {*} orderGuests
 * @param {*} orderServices
 * @returns {Room}
 */
function updateOrderRoomUsingAvailability(room, releventAvailability, orderGuests, orderServices) {
  /** @type {RoomRepo} */
  let matchingRoom;
  if (releventAvailability) {
    releventAvailability.getAllElements().some((avRoom) => {
      if (room.isMatchingRoom(avRoom)) {
        matchingRoom = avRoom;
        return true;
      }
      return false;
    });
  }
  if (matchingRoom == null) {
    room.fieldsHistory = [room.generateInternalJson(), ...room.fieldsHistory];
    room.resetForAvailability();
    return room;
  }
  const newRoom = room.buildFromSelectedAvailability(matchingRoom, orderGuests, orderServices);
  // state.order.getRoomsRepo().setElement(newRoom.getInternalId(), newRoom);
  return newRoom;
}

/**
 *
 * @param {Room} room
 * @param {*} availableRoomServices
 * @param {GuestRepo} guestRepo
 * @param {ServiceRepo} serviceRepo
 * @returns
 */
function updateOrderRoomServicesUsingAvailability(room, availableRoomServices, guestRepo, serviceRepo) {
/** @type {Service[]} */
  const newServices = [];
  const roomServices = extractRoomServices(room, serviceRepo);
  roomServices.forEach((service) => {
    const serviceGuests = extractServiceGuests(service, guestRepo);
    /** @type {ServiceRepo} */
    let match;
    if (availableRoomServices) {
      availableRoomServices.getAllElements().some((avService) => {
        if (service.isMatchingService(avService)) {
          if (avService.getMinAge() == null || avService.getMaxAge() == null) {
            match = avService;
            return true;
          }
          if (guestsAreWithinAgeRange(serviceGuests, avService.getMinAge(), avService.getMaxAge())) {
            match = avService;
            return true;
          }
        }
        return false;
      });
    }
    if (match == null) {
      service.fieldsHistory = [service.generateInternalJson(), ...service.fieldsHistory];
      service.resetForAvailability();
      newServices.push(service);
    } else {
      const roomGuests = extractRoomGuests(room, guestRepo);
      const newService = service.buildFromSelectedAvailability(match, guestRepo);
      newService.setDatesBasedOnPricingType(service);
      newService.setGuestsBasedOnPricingType(roomGuests);
      newServices.push(newService);
    //   state.order.getServicesRepo().setElement(newService.getInternalId(), newService);
    }
  });
  return newServices;
}

export function buildAvailabilityObject(order, availabilityData) {
  const availability = {
    services: [],
    rooms: [],
  };

  // build collection of available rooms for each room in order
  if (availabilityData !== null) {
    availability.rooms = availabilityData.rooms.map((roomsJson) => (
      new RoomRepo().buildFromInternalJson(roomsJson, order.getGuestsRepo(), order.getServicesRepo())
    ));

    // build collection of available services for each room in order
    availability.services = availabilityData.services.map((serviceJson) => (
      new ServiceRepo().buildFromInternalJson(serviceJson, order.getGuestsRepo())
    ));
  }
  return availability;
}
