import React, { useContext } from 'react';
import searchEnvironmentSelection from '../../Config/SearchEnvironmentSelection';
import { EnvironmentContext, EnvironmentValue } from '../../Context/EnvironmentContext';
import { SelectInput } from '../FormElements/FormElements';
import { ibsOrderDataActions } from '../OrderDisplay/orderState.reducer';

export default function OrderEnvironmentSelector({ orderEnvironmentId, dispatch }) {
  /** @type {EnvironmentValue} */
  const appEnvironment = useContext(EnvironmentContext);

  let display = <></>;

  if (appEnvironment.fixedOrderEnv == null) {
    display = (
      <>
        <span>Environment: </span>
        <SelectInput
          options={searchEnvironmentSelection}
          value={orderEnvironmentId}
          onChange={(e) => {
            dispatch({ type: ibsOrderDataActions.CHANGE_ENV, data: e.target.value });
          }}
        />
      </>
    );
  }

  return display;
}
