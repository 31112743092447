import React from 'react';
import PropTypes, { string } from 'prop-types';
import RoomRepo from '../../Shared/Domain/Rooms/Rooms.repo';
import GuestRepo from '../../Shared/Domain/Guests/Guests.repo';
import ServiceRepo from '../../Shared/Domain/Services/Services.repo';
import Room from '../../Shared/Domain/Rooms/Room.entity';
import DisplayGuestReference from './DisplayGuestReference';
import Guest from '../../Shared/Domain/Guests/Guest.entity';
import { DisplayOrderSection, DisplaySubSection } from './DisplayOrderSection';
import {
  Button, DatePicker, SelectInput, TextInput,
} from '../FormElements/FormElements';
import DisplayGuests, { MultiGuestSelect } from './DisplayGuests';
import DisplayServices, { MultiServiceSelect } from './DisplayServices';
import { extractRoomGuests, extractRoomServices } from '../../Shared/Domain/DomainServices/DomainServices';

/**
 * @typedef {{roomRepo: RoomRepo, guestRepo: GuestRepo, serviceRepo: ServiceRepo, availabilityRoomRepos: RoomRepo[], changeOrderElement: Function, removeOrderElement: Function}} Props
 */
class DisplayRoomsConfig {
  /**
   * @param {Props} props
   */
  constructor({
    roomRepo, guestRepo, serviceRepo, changeOrderElement, removeOrderElement, availabilityRoomRepos, availabilityServiceRepos,
  }) {
    this.roomRepo = roomRepo;
    this.guestRepo = guestRepo;
    this.serviceRepo = serviceRepo;
    this.changeOrderElement = changeOrderElement;
    this.removeOrderElement = removeOrderElement;
    this.availabilityRoomRepos = availabilityRoomRepos;
    this.availabilityServiceRepos = availabilityServiceRepos;
  }
}

/**
 * @param {Props} props
 */
function DisplayRooms(props) {
  const { roomRepo, changeOrderElement } = props;
  const displayRoomsConfig = new DisplayRoomsConfig(props);

  const display = roomRepo.getAllElements().map((room, i) => (
    <DisplayRoom
      key={room.getSupplierId()}
      room={room}
      displayRoomsConfig={displayRoomsConfig}
      availabilityRoomRepo={displayRoomsConfig.availabilityRoomRepos[i]}
      roomServiceRepo={displayRoomsConfig.availabilityServiceRepos[i]}
    />
  ));
  return (
    <DisplayOrderSection title="Rooms">
      {display}
      <Button onClick={(e) => { changeOrderElement(roomRepo.createNewElement()); }}>Add Room</Button>
    </DisplayOrderSection>
  );
}

/**
 *
 * @param {{room: Room, displayRoomsConfig: DisplayRoomsConfig}} props
 */
const DisplayAllRoomGuests = ({ room, displayRoomsConfig }) => {
  const { changeOrderElement, removeOrderElement, guestRepo } = displayRoomsConfig;

  /**
   *
   * @param {Guest} newGuest
   */
  function addNewGuest(newGuest) {
    room.addGuestId(newGuest.getInternalId());
    changeOrderElement(newGuest, room);
  }

  const roomGuestIds = room.getGuestIds();
  const roomGuests = guestRepo.getElementsByIds(roomGuestIds);
  const guestDisplay = (
    <DisplayGuests
      addNewGuest={addNewGuest}
      removeOrderElement={removeOrderElement}
      changeOrderElement={changeOrderElement}
      guestRepo={guestRepo}
      guests={roomGuests}
    />
  );
  const display = guestDisplay;
  return display;
};

DisplayAllRoomGuests.propTypes = {
  room: PropTypes.instanceOf(Room).isRequired,
  displayRoomsConfig: PropTypes.instanceOf(DisplayRoomsConfig).isRequired,
};

/**
 *
 * @param {{room: Room, displayRoomsConfig: DisplayRoomsConfig}} props
 */
const DisplayAllRoomServices = ({ room, displayRoomsConfig, roomServiceRepo }) => {
  const {
    changeOrderElement, removeOrderElement, guestRepo, serviceRepo,
  } = displayRoomsConfig;

  function addNewService(newService) {
    room.addServiceId(newService.getInternalId());
    changeOrderElement(newService, room);
  }

  const roomGuests = extractRoomGuests(room, guestRepo);
  const roomServices = extractRoomServices(room, serviceRepo);

  const display = (
    <DisplayServices
      addNewService={addNewService}
      changeOrderElement={changeOrderElement}
      availabilityServiceRepo={roomServiceRepo}
      removeOrderElement={removeOrderElement}
      guests={roomGuests}
      services={roomServices}
      guestRepo={guestRepo}
      serviceRepo={serviceRepo}
    />
  );
  return display;
};

DisplayAllRoomGuests.propTypes = {
  room: PropTypes.instanceOf(Room).isRequired,
  displayRoomsConfig: PropTypes.instanceOf(DisplayRoomsConfig).isRequired,
};

/**
 * @param {{room: Room, displayRoomsConfig: DisplayRoomsConfig, availabilityRoomRepo: RoomRepo | undefined}} props
 */
const DisplayRoom = ({
  room, displayRoomsConfig, availabilityRoomRepo, roomServiceRepo,
}) => {
  const {
    changeOrderElement, removeOrderElement, guestRepo, serviceRepo,
  } = displayRoomsConfig;

  function handleChange(callback) {
    return (e) => {
      callback(e.target.value);
      changeOrderElement(room);
    };
  }

  function handleRoomChange(e) {
    const roomId = e.target.value;
    changeRoomToAvailabilityEntry(roomId);
  }

  function changeRoomToAvailabilityEntry(availabilityId) {
    const availRoom = availabilityRoomRepo.getElement(availabilityId);
    const newRoom = room.buildFromSelectedAvailability(availRoom, guestRepo, serviceRepo);
    newRoom.fieldsHistory = [newRoom.generateInternalJson(), room.generateInternalJson(), ...room.fieldsHistory];
    changeOrderElement(newRoom);
  }

  const roomOptions = {};
  if (availabilityRoomRepo != null) {
    availabilityRoomRepo.getAllElements().forEach((avRoom) => {
      if (avRoom.getFriendlyName() != null) {
        roomOptions[avRoom.buildOptionKey()] = avRoom.getInternalId();
      }
    });
  }

  roomOptions[room.buildOptionKey()] = 'current';

  const originalStateString = room.getOriginalStateAsFriendlyString();
  const prevStateString = room.getPreviousStateAsFriendlyString();

  // eslint-disable-next-line react/jsx-one-expression-per-line
  const originalStateDisplay = originalStateString ? (<p>Orig: {originalStateString}</p>) : null;

  // eslint-disable-next-line react/jsx-one-expression-per-line
  const prevStateDisplay = prevStateString ? (<p>Prev: {prevStateString}</p>) : null;

  return (
    <DisplaySubSection title={`Room ${room.getSupplierId()}`}>
      {/* <DatePicker disabled onChange={handleChange((val) => room.setStartDate(val))} value={room.getStartDate() || ''} />
      <DatePicker disabled onChange={handleChange((val) => room.setEndDate(val))} value={room.getEndDate() || ''} />
      <TextInput label="Hotel Id" onChange={handleChange((val) => room.setHotelCode(val))} value={room.getHotelCode() || ''} /> */}
      {originalStateDisplay}
      {prevStateDisplay}
      <SelectInput value="current" onChange={handleRoomChange} options={roomOptions} />
      <p>{`${room.getTotalBeforeTax()} / ${room.getTotalAfterTax()} after tax`}</p>
      <DisplayAllRoomGuests room={room} displayRoomsConfig={displayRoomsConfig} />
      <DisplayAllRoomServices room={room} displayRoomsConfig={displayRoomsConfig} roomServiceRepo={roomServiceRepo} />
      <Button onClick={() => { removeOrderElement(room); }}>Remove Room</Button>
    </DisplaySubSection>
  );
};

DisplayRoom.propTypes = {
  room: PropTypes.instanceOf(Room).isRequired,
  displayRoomsConfig: PropTypes.instanceOf(DisplayRoomsConfig).isRequired,
};

export default DisplayRooms;
