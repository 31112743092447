import { React, useContext, useState } from 'react';
import { AuthenticationContext } from '../../Context/AuthenticationContext';
import AuthenticationValue from '../../Domain/AuthenticationValue';
import OrderDisplay from '../OrderDisplay/OrderDisplay';

export default function MainDisplay() {
  /** @type {AuthenticationValue} */
  const authenticationContext = useContext(AuthenticationContext);

  let display;
  if (authenticationContext.isPending()) {
    display = <p>Setting up...</p>;
  } else if (authenticationContext.hasPermission(authenticationContext.permissionTypes.VIEW)) {
    display = <OrderDisplay />;
  } else {
    display = <p>You do not have permission.</p>;
  }

  return display;
}
