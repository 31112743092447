import React from 'react';
import Order from '../../Shared/Domain/Order/Order.entity';
import { TextInput } from '../FormElements/FormElements';

/**
 *
 * @param {{order: Order}} props
 */
export default function DisplaySupplierCodes({ order }) {
  const supplierCodes = order.getSupplierCodes();
  const display = supplierCodes.map((supplierCode) => (
    <TextInput disabled label={supplierCode.getName()} value={supplierCode.getValue()} />
  ));
  return display;
}
