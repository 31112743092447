export default class RequestBody {
  constructor() {
    this.data = {};
    this.config = {
      env: null,
    };
  }

  getData() {
    return this.data;
  }

  /**
   * @param {Object.<string, string>} value
   */
  setData(value) {
    this.data = value;
  }

  setConfig(env) {
    this.config.env = env;
  }

  buildFromJsonBody(jsonBody) {
    this.data = jsonBody.data;
    this.config = jsonBody.config;
    return this;
  }

  getEnv() {
    return this.config.env;
  }

  generateJsonBody() {
    if (this.config.env == null) {
      throw new Error('Attempted to build a request body without setting the envrionment (prod/stage 4 etc.)');
    }
    const body = {
      data: this.data,
      config: this.config,
    };

    return JSON.stringify(body);
  }
}
