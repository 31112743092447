import React from 'react';
import BackendError from '../../Shared/Domain/Error/BackendError';
import Order from '../../Shared/Domain/Order/Order.entity';
import { Notification } from '../FormElements/FormElements';
import { ibsOrderDataActions } from './orderState.reducer';

function responseIsSuccess(response) {
  return (!(response instanceof BackendError) && response != null);
}

function extractErrorMessage(response) {
  if (response instanceof BackendError) {
    return response.getMessage();
  }
  return '';
}

/**
 *
 * @param {*} orderService
 * @param {Order} order
 * @param {*} options
 */
async function availabilityProcess(orderService, order, options) {
  const responseBody = await orderService.getAvailability(order.generateInternalJson(), options);
  const response = responseBody.extractDataOrError();

  /** @type {{availability: string | Error, notifications: []}} */
  const returnData = {
    availability: null,
    notifications: [],
  };

  if (responseIsSuccess(response)) {
    returnData.availability = response;
  } else {
    const errMessage = `Failed to get availability for this order: ${extractErrorMessage(response)}`;
    returnData.notifications.push(<Notification type="error" title="Error Retrieving Availability" message={errMessage} />);
    returnData.availability = new Error(errMessage);
  }

  return returnData;
}

/**
 * Fetch order and availability data from the backend
 * @param {*} param0
 */
export async function fetchOrderData({ state, dispatch, orderService }) {
  const orderDetailsToFetch = state.orderDetails;
  if (orderDetailsToFetch.orderId !== null) {
    const orderResponseBody = await orderService.getOrderDataPromise(orderDetailsToFetch.orderId);
    const orderResponse = orderResponseBody.extractDataOrError();

    const returnData = {
      order: orderResponse,
      availability: null,
      notifications: [],
    };

    if (responseIsSuccess(orderResponse)) {
      // order is good, try getting availability
      const loadedOrder = new Order().buildFromInternalJson(orderResponse);

      // send request for enhanced search availability
      const availabilityOptions = { enhanced: true };
      const { availability, notifications } = await availabilityProcess(orderService, loadedOrder, availabilityOptions);

      returnData.availability = availability;
      returnData.notifications = notifications;
    } else {
      // order did not return correctly
      const errMessage = `Could not fetch the order: ${extractErrorMessage(orderResponse)}`;
      returnData.order = new Error(errMessage);
      returnData.notifications.push(<Notification type="error" title="Error Retrieving Order" message={errMessage} />);
    }

    dispatch({ type: ibsOrderDataActions.LOAD_ORDER, data: returnData });
  }
}

export async function fetchAvailability({ state, dispatch, orderService }) {
  const options = state.action.data;
  if (state.action.type === ibsOrderDataActions.SEND_AVAILABILITY) {
    const { availability, notifications } = await availabilityProcess(orderService, state.order, options);
    const returnData = {
      availability,
      notifications,
    };

    dispatch({ type: ibsOrderDataActions.LOAD_AVAILABILITY, data: returnData });
  }
}

export async function submitModification({ state, dispatch, orderService }) {
  const { orderId } = state.orderDetails;
  const order = state.order;

  if (orderId == null) {
    console.log('No order selected to modify.');
  } else if (state.action.type !== ibsOrderDataActions.SEND_MODIFICATION) {
    console.log('Cannot send modification as other operations are taking place.');
  } else {
    const responseBody = await orderService.sendUpdatedOrder(
      order.getOrderId(),
      order.getOrderTicketIds(),
      order.generateInternalJson(),
    );

    const response = responseBody.extractDataOrError();

    const returnData = {
      modification: response,
      notifications: [],
    };

    if (responseIsSuccess(response)) {
      const infoMessage = 'The modification request has been successfully sent to the supplier.';
      returnData.notifications.push(<Notification type="info" title="Success Modifying" message={infoMessage} />);
    } else {
      const errMessage = `Error when sending modification: ${extractErrorMessage(response)}`;
      returnData.modification = new Error(errMessage);
      returnData.notifications.push(<Notification type="error" title="Error Modifying" message={errMessage} />);
    }

    dispatch({ type: ibsOrderDataActions.LOAD_MODIFICATION, data: returnData });
  }
}
