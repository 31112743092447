import GuestRepo from '../Guests/Guests.repo';
import Room from '../Rooms/Room.entity';
import RoomRepo from '../Rooms/Rooms.repo';
import Service from '../Services/Service.entity';
import ServiceRepo from '../Services/Services.repo';

/**
 *
 * @param {Room} room
 * @param {GuestRepo} guestRepo
 * @returns
 */
export function extractRoomGuests(room, guestRepo) {
  const ids = room.getGuestIds();
  const guests = guestRepo.getElementsByIds(ids);
  return guests;
}

/**
 *
 * @param {Room} room
 * @param {ServiceRepo} serviceRepo
 * @returns
 */
export function extractRoomServices(room, serviceRepo) {
  const ids = room.getServiceIds();
  const services = serviceRepo.getElementsByIds(ids);
  return services;
}

/**
 *
 * @param {Service} service
 * @param {GuestRepo} guestRepo
 * @returns
 */
export function extractServiceGuests(service, guestRepo) {
  const ids = service.getGuestIds();
  const guests = guestRepo.getElementsByIds(ids);
  return guests;
}

/**
 * @returns {Object<string,number>}
 */
export function buildGuestAgeCounts(guests) {
  // determine guest counts using guests
  // should be put into a domain service
  /**
     * @type {Object<string,number>}
     */
  const ageCounts = {};
  guests.forEach((guest) => {
    const age = guest.getAge();
    if (!(age in ageCounts)) {
      ageCounts[age] = 0;
    }
    ageCounts[age] = Number(ageCounts[age]) + 1;
  });

  return ageCounts;
}

export function buildRoomGuestAgeCounts(room, guestRepo) {
  const roomGuests = extractRoomGuests(room, guestRepo);
  return buildGuestAgeCounts(roomGuests);
}

/**
 *
 * @param {Room} room
 * @param {RoomRepo} roomRepo
 * @param {ServiceRepo} serviceRepo
 * @param {GuestRepo} guestRepo
 */
export function removeRoom(id, roomRepo, serviceRepo, guestRepo) {
  /*
  remove room from repo
  remove services associated with room
  remove guests associated with room
  renumber all elements
  */
  const room = roomRepo.getElement(id);
  const roomServiceIds = room.getServiceIds();
  const roomGuestIds = room.getGuestIds();

  roomRepo.removeElement(room.getInternalId());
  roomServiceIds.forEach((serviceId) => {
    removeService(serviceId, roomRepo, serviceRepo);
  });
  roomGuestIds.forEach((guestId) => {
    removeGuest(guestId, roomRepo, serviceRepo, guestRepo);
  });

  roomRepo.renumberElements();
  serviceRepo.renumberElements();
  guestRepo.renumberElements();
}

/**
 *
 * @param {Service} service
 * @param {RoomRepo} roomRepo
 * @param {ServiceRepo} serviceRepo
 */
export function removeService(id, roomRepo, serviceRepo) {
  /*
  remove service from repo
  garbage collect any references to service id in rooms
  renumber services
  */
  serviceRepo.removeElement(id);
  roomRepo.getAllElements().forEach((room) => {
    room.removeServiceId(id);
  });

  serviceRepo.renumberElements();
}

/**
 *
 * @param {string} id
 * @param {RoomRepo} roomRepo
 * @param {ServiceRepo} serviceRepo
 * @param {GuestRepo} guestRepo
 */
export function removeGuest(id, roomRepo, serviceRepo, guestRepo) {
  /*
  remove guest from repo
  garbage collect any references to guest id in rooms
  garbage collect any references to guest id in services
  */
  guestRepo.removeElement(id);
  roomRepo.getAllElements().forEach((room) => {
    room.removeGuestId(id);
  });
  serviceRepo.getAllElements().forEach((service) => {
    service.removeGuestId(id);
  });

  guestRepo.renumberElements();
}

export function guestIsWithinRange(guest, minAge, maxAge) {
  const acceptsAdults = (maxAge >= 18);
  const guestAge = Number(guest.getAge());
  if (guestAge === -1) {
    return acceptsAdults;
  }
  return (guestAge >= minAge && guestAge <= maxAge);
}

export function guestsAreWithinAgeRange(serviceGuests, minAge, maxAge) {
  const areWithinRange = serviceGuests.every((guest) => (
    guestIsWithinRange(guest, minAge, maxAge)
  ));
  return areWithinRange;
}
