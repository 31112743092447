import './App.css';
import { React } from 'react';
import PropTypes from 'prop-types';
import { InitialOrderContextProvider } from '../../Context/InitialOrderContext';
import { AuthenticationContextProvider } from '../../Context/AuthenticationContext';
import MainDisplay from '../MainDisplay/MainDisplay';
import { EnvironmentContextProvider } from '../../Context/EnvironmentContext';

function App() {
  return (
    <EnvironmentContextProvider>
      <AuthenticationContextProvider>
        <InitialOrderContextProvider>
          <div className="App">
            <header className="App-header">
              <MainDisplay />
            </header>
          </div>
        </InitialOrderContextProvider>
      </AuthenticationContextProvider>
    </EnvironmentContextProvider>

  );
}

export default App;
