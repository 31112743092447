import React, { useMemo } from 'react';

export const InitialOrderContext = React.createContext();

export const InitialOrderContextProvider = ({ children }) => {
  const value = useMemo(() => ({
    orderId: window.hotel_modification_app_order_id,
  }), [window.hotel_modification_app_order_id]);

  return (
    <InitialOrderContext.Provider value={value}>
      { children }
    </InitialOrderContext.Provider>
  );
};

export default InitialOrderContext;
