import { getClassProperties } from '../../util';

export default class BackendError {
  constructor(message) {
    this.name = this.constructor.name;
    this.message = message;
  }

  generateJson() {
    return getClassProperties(this);
  }

  buildFromJson(fields) {
    const { message } = fields;
    this.message = message;
    return this;
  }

  getMessage() {
    return this.message;
  }
}
