import React from 'react';
import PropTypes from 'prop-types';
import Guest from '../../Shared/Domain/Guests/Guest.entity';
import GuestRepo from '../../Shared/Domain/Guests/Guests.repo';
import {
  Button, MultiSelectInput, SelectInput, TextInput,
} from '../FormElements/FormElements';
import { DisplayOrderSection, DisplaySubSection } from './DisplayOrderSection';

/**
 *
 * @param {{guestRepo: GuestRepo, changeOrderElement: Function, removeOrderElement: Function, guests: Guest[]}} props
 */
const DisplayGuests = ({
  guestRepo, changeOrderElement, removeOrderElement, guests, addNewGuest,
}) => {
  const display = guests.map((guest) => (
    <DisplayGuest key={guest.getInternalId()} guest={guest} removeOrderElement={removeOrderElement} changeOrderElement={changeOrderElement} />
  ));
  return (
    <DisplayOrderSection title="Guests" direction="column">
      {display}
      <Button onClick={(e) => { addNewGuest(guestRepo.createNewElement()); }}>Add Guest</Button>
    </DisplayOrderSection>
  );
};

/**
 *
 * @param {{guest: Guest, changeOrderElement:Function}} props
 */
const DisplayGuest = ({ guest, changeOrderElement, removeOrderElement }) => {
  /**
   * @param {Function} callback
   */
  function handleChange(callback) {
    return (e) => {
      callback(e.target.value);
      changeOrderElement(guest);
    };
  }
  return (
    <DisplaySubSection direction="row">
      <TextInput label="Given Name" value={guest.getGivenName()} onChange={handleChange((val) => guest.setGivenName(val))} />
      <TextInput label="Surname" value={guest.getSurname()} onChange={handleChange((val) => guest.setSurname(val))} />
      <TextInput label="Age" value={guest.getAge()} onChange={handleChange((val) => guest.setAge(val))} />
      <Button onClick={() => { removeOrderElement(guest); }}>Remove Guest</Button>
    </DisplaySubSection>
  );
};
DisplayGuest.propTypes = {
  guest: PropTypes.instanceOf(Guest).isRequired,
};

/**
 *
 * @param {Guest} guest
 */
function getGuestReferenceString(guest) {
  return `#${guest.getSupplierId()}: ${guest.getGivenName()} ${guest.getSurname()} [${guest.getAge()}y/o]`;
}

/**
 * @param {{guests: Guest[], initialValueIds: String[]}} props
 */
export const MultiGuestSelect = ({ guests, initialValueIds, onChange }) => {
  const limitedGuestsOptions = {};
  guests.forEach((guest) => {
    limitedGuestsOptions[getGuestReferenceString(guest)] = guest.getInternalId();
  });
  const display = <MultiSelectInput onChange={(e) => { onChange(e); }} options={limitedGuestsOptions} value={initialValueIds} />;

  return display;
};

export default DisplayGuests;
