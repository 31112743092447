export const PermissionTypes = {
  VIEW: 'view',
  MODIFY: 'mod',
  VIEW_JSON: 'viewJson',
};

const p = PermissionTypes;

export const rolePermissions = {
  developer: [p.VIEW, p.MODIFY, p.VIEW_JSON],
};

export const standalonePermissions = [
  p.VIEW, p.MODIFY, p.VIEW_JSON,
];
