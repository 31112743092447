import { React, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, TextInput } from '../FormElements/FormElements';
import { ibsOrderDataActions } from '../OrderDisplay/orderState.reducer';

/**
 *
 * @param {{orderDetails: {orderId: string, orderTicketIds: string[]}, dispatch: Function}} param0
 */
export default function OrderIdForm({ orderDetails, dispatch }) {
  const [orderId, setOrderId] = useState(orderDetails.orderId);

  /**
   * @param {string} csvValue
   */
  function buildOrderTicketIds(csvValue) {
    const ids = csvValue.split(',');
    ids.map((id) => (id.trim()));
    return ids;
  }

  /**
   * @param {Event} e
   */
  function handleSubmit(e) {
    e.preventDefault();
    dispatch({
      type: ibsOrderDataActions.SEND_ORDER,
      data: {
        orderId,
      },
    });
  }

  let displayTitle = '';

  if (orderDetails.orderId === null) {
    displayTitle = <h3>Please Enter the Order Details</h3>;
  }

  return (
    <div>
      {displayTitle}
      <form onSubmit={handleSubmit}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TextInput value={orderId} onChange={(e) => setOrderId(e.target.value)} label="Order Id" />
          <Button type="submit">Search</Button>
        </div>
      </form>
    </div>
  );
}

OrderIdForm.propTypes = {
  orderId: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
};

OrderIdForm.defaultProps = {
  orderId: PropTypes.undefined,
};
