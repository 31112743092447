import React from 'react';
import { ibsOrderDataActions } from './orderState.reducer';

function getMessageByAction(action) {
  switch (action.type) {
    case ibsOrderDataActions.SEND_ORDER:
      return 'Loading Order...';
    case ibsOrderDataActions.SEND_AVAILABILITY:
      return 'Loading Availability...';
    case ibsOrderDataActions.SEND_MODIFICATION:
      return 'Sending Modification...';
    case ibsOrderDataActions.LOAD_MODIFICATION:
      return 'Modification has been sent to ibs.';
    default:
      return action.type;
  }
}

export default function CurrentActionDisplay({ action }) {
  return (
    <div style={{
      position: 'sticky',
      bottom: '0px',
      padding: '7px',
      background: 'rgba(63, 127, 191, 0.75)',
      zIndex: 999,
      width: '100%',
      textAlign: 'center',
    }}
    >
      <h2>{getMessageByAction(action)}</h2>
    </div>
  );
}
