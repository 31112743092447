/* eslint-disable class-methods-use-this */

import BackendError from '../Shared/Domain/Error/BackendError';
import ResponseBody from '../Shared/Domain/ResponseBody/ResponseBody.object';

function buildResponseObj(json) {
  return new ResponseBody().buildFromJson(json);
}

export default class AtdRolesService {
  /**
 * @param {Object<string,*>} obj
 * @returns {string}
 */
  buildQueryString(obj) {
    if (obj) {
      return Object.entries(obj).map(
        ([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`,
      ).join('&');
    }
    return '';
  }

  async requestAtdRoles(apiSid, atdDomain) {
    let response;
    if (atdDomain == null) {
      throw new Error('Atd domain supplied is empty');
    }
    try {
      response = await fetch(`${atdDomain}/api/session?extras=roles`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${apiSid}`,
        },
      });
      response = await response.json();
      response = new ResponseBody(
        ResponseBody.statusTypes.SUCCESS,
        response,
      );
    } catch (err) {
      console.log(err);
      response = new ResponseBody(
        ResponseBody.statusTypes.ERROR,
        new BackendError('Failed to get permissions'),
      );
    }
    return buildResponseObj(response);
  }
}
