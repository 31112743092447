import { useContext } from 'react';
import { AuthenticationContext } from '../../Context/AuthenticationContext';
import AuthenticationValue from '../../Domain/AuthenticationValue';

export default function usePermissionsCheck(...permissionNames) {
  /** @type {AuthenticationValue} */
  const authenticationContext = useContext(AuthenticationContext);

  const hasAllPermissions = permissionNames.every((permissionName) => (
    authenticationContext.hasPermission(permissionName)
  ));

  return hasAllPermissions;
}
