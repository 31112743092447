import React from 'react';
import ReactDOM from 'react-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './index.css';
import App from './Components/App/App';
// import reportWebVitals from './reportWebVitals';

if (window.hotel_modification_app_ready == null || !window.hotel_modification_app_ready) {
  window.addEventListener('hotel-modification-app-launch', launchApp);
} else {
  launchApp();
}

function launchApp() {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('hotel_modification_tool'),
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
