import { PermissionTypes, rolePermissions } from '../Config/Permissions';

function getPermissionsBasedOnRoles(roles) {
  const permissionSet = new Set();
  roles.forEach((roleName) => {
    if (rolePermissions[roleName]) {
      rolePermissions[roleName].forEach((permission) => {
        permissionSet.add(permission);
      });
    }
  });
  return [...permissionSet];
}
export default class AuthenticationValue {
  constructor() {
    /** @type {String} */this.apiSid = null;
    /** @type {String[]} */ this.atdRoles = [];
    this.permissionTypes = PermissionTypes;
    this.permissions = [];
    this.pending = true;
  }

  isPending() {
    return this.pending;
  }

  setPendingStatus(value) {
    this.pending = value;
  }

  setUserAsUnauthenticated() {
    this.setApiSid(null);
    this.setAtdRoles([]);
    this.setPermissions([]);
    this.setPendingStatus(false);
  }

  setApiSid(value) {
    this.apiSid = value;
  }

  /** @returns {string | null} */
  getApiSid() {
    return this.apiSid;
  }

  assignPermissionsBasedOnRoles(roles) {
    this.permissions = getPermissionsBasedOnRoles(roles);
  }

  setAtdRoles(value) {
    this.atdRoles = value;
    this.assignPermissionsBasedOnRoles(this.atdRoles);
  }

  getAtdRoles() {
    return this.atdRoles;
  }

  hasPermission(permission) {
    return this.getPermissions().includes(permission);
  }

  getPermissions() {
    return this.permissions;
  }

  setPermissions(...permissions) {
    this.permissions = [...permissions];
  }
}
