/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import {
  React,
} from 'react';

import { Button, DatePicker, TextInput } from '../FormElements/FormElements';
import DisplayRooms from '../OrderElements/DisplayRooms';

import Room from '../../Shared/Domain/Rooms/Room.entity';
import Service from '../../Shared/Domain/Services/Service.entity';
import Guest from '../../Shared/Domain/Guests/Guest.entity';
import { DisplayOrderSection } from '../OrderElements/DisplayOrderSection';
import Order from '../../Shared/Domain/Order/Order.entity';
import { ibsOrderDataActions } from './orderState.reducer';
import { getSafely } from '../../Shared/util';

import JSONDisplay from './JSONDisplay';
import DisplaySupplierCodes from '../DisplaySupplierCodes/DisplaySupplierCodes';
import Accordian from '../FormElements/Accordian';
import { PermissionTypes } from '../../Config/Permissions';
import DisplayIfUserHasPermissions from '../DisplayIfUserHasPermissions';
/**
 *
 * @param {{
 * order: Order,
 * submitModification: Function,
 * dispatch: Function,
 * availability: {},
 * }} props
 */
export default function OrderModifyForm({
  order, dispatch, availability, currentAction,
}) {
  const roomRepo = order.getRoomsRepo();
  const guestRepo = order.getGuestsRepo();
  const serviceRepo = order.getServicesRepo();
  const search = order.search;

  const availServiceRepos = getSafely(() => (availability.services)) || [];
  const availRoomRepos = getSafely(() => (availability.rooms)) || [];

  /**
   * @param {...Guest| Room | Service} orderElement
   */
  function changeOrderElement(...orderElement) {
    dispatch({ type: ibsOrderDataActions.EDIT_MULTIPLE_ORDER_ELEMENTS, data: orderElement });
  }

  /**
   * @param {Guest| Room | Service} orderElement
   */
  function removeOrderElement(orderElement) {
    dispatch({ type: ibsOrderDataActions.REMOVE_ORDER_ELEMENT, data: orderElement });
  }

  function updateSearch(newSearch) {
    dispatch({ type: ibsOrderDataActions.UPDATE_SEARCH, data: newSearch });
  }

  function updateOrder(modifiedOrder) {
    dispatch({ type: ibsOrderDataActions.UPDATE_ORDER, data: modifiedOrder });
  }

  function refreshAvailability(availOptions) {
    const oldRooms = roomRepo.getAllElements();

    oldRooms.forEach((oldRoom) => {
      // setting room dates by reference to objects for now
      oldRoom.setStartDate(search.getStartDate());
      oldRoom.setEndDate(search.getEndDate());
      oldRoom.setHotelCode(search.getHotelId());
    });
    dispatch({ type: ibsOrderDataActions.SEND_AVAILABILITY, data: availOptions });
  }

  // display section = 1 type of field i.e. guests
  const displaySections = [];

  displaySections.push(
    <DisplayOrderSection title="Order Details">
      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        <TextInput label="Hotel Id" value={search.getHotelId() || ''} onChange={(e) => { search.setHotelId(e.target.value); updateSearch(search); }} />
        <TextInput disabled label="IBS Status" value={order.getIbsReservationStatus()} />
        <TextInput disabled label="Atd Order Status" value={order.getAtdOrderStatus()} />
        <DisplaySupplierCodes order={order} />
      </div>

      <DisplayIfUserHasPermissions permissionNames={[PermissionTypes.VIEW_JSON]}>
        <Accordian title="Internal JSON">
          <JSONDisplay>{order.generateInternalJson()}</JSONDisplay>
        </Accordian>
      </DisplayIfUserHasPermissions>
    </DisplayOrderSection>,
  );

  // displaySections.push(JSON.stringify(orderData));
  displaySections.push(
    <DisplayOrderSection title="Availability Search">
      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        <DatePicker value={search.getStartDate()} onChange={(e) => { search.setStartDate(e.target.value); updateSearch(search); }} />
        <DatePicker value={search.getEndDate()} onChange={(e) => { search.setEndDate(e.target.value); updateSearch(search); }} />
        <Button onClick={() => { refreshAvailability({ enhanced: false }); }}>Refresh Availability</Button>
        <Button onClick={() => { refreshAvailability({ enhanced: true }); }}>Refresh (Enhanced) Availability</Button>
      </div>
    </DisplayOrderSection>,
  );
  displaySections.push(
    <DisplayRooms
      key="rooms"
      availabilityRoomRepos={availRoomRepos}
      availabilityServiceRepos={availServiceRepos}
      changeOrderElement={changeOrderElement}
      removeOrderElement={removeOrderElement}
      roomRepo={roomRepo}
      guestRepo={guestRepo}
      serviceRepo={serviceRepo}
    />,
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{
        display: 'flex', flexWrap: 'wrap', flexDirection: 'column', textAlign: 'left',
      }}
      >
        {displaySections}
      </div>
      <Button onClick={() => { dispatch({ type: ibsOrderDataActions.SEND_MODIFICATION }); }}>Send Modifications</Button>
    </div>
  );
}

OrderModifyForm.propTypes = {
  order: PropTypes.instanceOf(Order).isRequired,
};
